.overlay-display {
    height: 100%;
    width: 100%;
    position: absolute;
    background: black;
    opacity: .5;
    z-index: 9999999999999;
}

.mobile-youtube-search-container {
    width: 100%;
    background: white;
    height: 48px;
}

.mobile-search-youtube-case {
    z-index: 99999999999999;
    position: absolute;
    top: 0;
    height: 48px;
    width: 100%;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-content: center;
}

.mobile-right-side-container {
    display: flex;
    width: 100%;
    justify-content: center;
}

.mobile-search-button-youtube {
    background: white;
    /*width: 100%;*/
    height: 20px;
}

.mobile-search-button-youtube img {
    height: 100%;
    object-fit: contain;
    /*margin-bottom: 5px;*/
}

.popout-search-box {
    width: 90%;
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: .75px solid black;
    margin-right: 10px;
    margin-left: 10px;
}

.popout-search-box::placeholder {
    font-family: 'Comfortaa', cursive;
    font-size: .75rem;
}