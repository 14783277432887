.media-component-wrapper {
    width: 100%;
    height: 100%;
    border-bottom: solid .25px grey;
    margin-bottom: 10px;
}

.media-component-bottom-wrapper {
    padding: 5px;
}

.media-component-media-description {
    font-size: .9rem;
}

.media-component-cover img {
    /*width: 100%;*/
    /*overflow: hidden;*/
    /*position: absolute;*/
    /*top: 0;*/
    /*left: 0;*/
    /*bottom: 0;*/
    /*right: 0;*/
    position: absolute;
    top: -9%;
    /* left: 0; */
    /* width: 100%; */
    /* height: 100%; */
    display: block;
    overflow: hidden;
    /* top: 0; */
    /* left: 0; */
    width: 100%;
    /* height: 100%; */
    /* width: 100%; */
    /* overflow: hidden; */
    /* position: absolute; */
    /* top: 0; */
    /* left: 0; */
    /* bottom: 0; */
    /* right: 0; */
    /* left: 0; */
    /* width: 100%; */
    /* height: 100%; */
    display: block;
    overflow: hidden;
}

.media-component-media-wrapper {
    height: 0;
    padding-top: 75%;
    /*margin-bottom: .5rem;*/
    background: black;
    position: relative;
}

.media-component-cover {
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    overflow: hidden;
    display: flex;
    justify-content: center
}

.media-component-cover img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.media-component-videosharemethod {
    background: rgba(52, 160, 196, 1);
    color: white;
    opacity: .8;
    font-weight: 600;
    font-size: 1rem;
    padding: 4px;
    /*width: 100%;*/
    position: absolute;
    top: 0;
    z-index: 99;
}

.media-component-videosharemethod div {
    width: 100%;
}

.list-view-wrapper {
    display: grid;
    grid-template-columns: 30% 70%;
    column-gap: 1rem;
}

.media-top-bar-wrapper {
    display: flex;
    justify-content: space-between;
}

.media-component-subbarurl-wrapper {
    width: 100%;
    padding-top: 3%;
    position: absolute;
    bottom: 0;
}

.media-component-subbarurl-wrapper img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.media-component-title-time-wrapper {
    border-bottom: solid #BEBEBE 2px;
    display: flex;
    align-items: flex-end;
    padding: 10px 0 10px 0;
    margin: 0;
}

.media-component-created-time, .media-component-creator {
    font-size: .8rem;
    font-weight: 500;
}

.media-component-creator img {
    padding-right: .25vw;
}

.media-component-media-title {
    padding: 10px 0 0 0;
}

.media-component-creator {
    display: flex;
}

.media-component-created-time {
    display: flex;
}

.media-component-created-time img {
    padding-right: .25vw;
}

.media-component-icon {

}

.public-views-wrapper {
    color: #02bbef;
    font-family: 'Comfortaa', cursive;
    font-size: .8rem;
}

.media-component-timeblock {
    margin-right: 2%;
    margin-bottom: 5%;
    padding: 2px;
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 14px;
    color: white;
    z-index: 99;
}

.media-component-timeblock div {
    width: 100%;
    padding: 2px;
}

.nopadding {
    padding: 0 !important;
    margin: 0 !important;
}

.lite-padding {
    padding: 8px;
    margin: 8px;
}

.media-component-creator div {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #2F2F2F;
}

.media-list-date-time {
    font-size: .8rem;
    padding-top: .3rem;
    font-family: 'Comfortaa', cursive;
}

.listview-container {
    border-bottom: solid 1px grey;
    padding-bottom: .425rem;
}

/*.media-component-creator div:hover {*/
/*    background: white;*/
/*    overflow: visible;*/
/*    z-index: 99;*/
/*}*/

/*.media-component-creator:hover {*/
/*    background: white;*/
/*    overflow: visible;*/
/*    z-index: 99;*/
/*    position: absolute;*/
/*}*/

.left-media-image .media-cover-image {
     width: 100%;
     height: 100%;
     object-fit: cover;
     overflow: hidden;
     max-height: 13vh;
 }

.left-media-image .list-bar-img {
    width: 100%;
    /*height: 100%;*/
}

.listview-title-wrapper {
    font-family: 'Comfortaa', cursive;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.6rem;
    display: flex;
    justify-content: space-between;
}

.listview-title-wrapper i {
    font-size: 1.5rem;
    padding-right: 15px;
}

.reputation-list-view {
    display: flex;
    white-space: pre;
    /*padding-top: .5rem;*/
    /*justify-content: space-between;*/
}

.media-list-description {
    font-size: .8rem;
    text-overflow: ellipsis;
    min-height: 40px;
}

.media-component-created-time div {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-subbar-wrapper img {
    height: .5rem;
    position: absolute;
    bottom: -.5rem;
}

/*.media-component-created-time div:hover {*/
/*    background: white;*/
/*    overflow: visible;*/
/*    z-index: 99;*/
/*}*/

/*.media-component-created-time:hover {*/
/*    background: white;*/
/*    overflow: visible;*/
/*    z-index: 99;*/
/*    position: absolute;*/

/*}*/

.media-component-divider {
    width: 100%;
    border-bottom: solid 2px #BEBEBE;
    padding-top: 5px;
}

.listView-wrapper {
    display: flex;
}

.media-component-wrapper-list {
    display: flex;
    padding-bottom: 2rem;
}

.media-component-media-wrapper-list {
    position: relative;
    margin-bottom: 1rem;
    margin-right: 1rem;
    display: inline-block;
}

.media-component-media-wrapper-list .media-cover-image {
    width: 344px;
    height: 210px;
    object-fit: cover;
    position: relative;
}

.edit-kabob-wrapper {
    /*padding-right: 1rem;*/
    padding-top: 1rem;
    cursor: pointer;
}

.edit-kabob-wrapper i {
    font-size: 1.25rem;
}

.media-component-subbarurl-wrapper {
}

.media-component-media-play-list {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 0;
    z-index: 99;
}

.media-component-media-play-list i {
    font-size: 6rem;
    color: white;
    opacity: .7;
}

.media-component-info-list {
    position: relative;
    width: 100%;
}

.media-component-list-button-wrapper {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    right: 0;
}

.media-component-list-button-wrapper button {
    margin-right: 10px;
    background: white;
    font-size: 1.2rem;
    font-family: 'Comfortaa', cursive;
    font-weight: bold;
    border: solid #00BAF2 2px;
    color: #00BAF2;
}

.media-component-timeclock {
    color: white;
    font-size: .9rem;
    font-weight: 600;
    background-color: black;
}

.list-media-component-timeclock {
    color: white;
    font-size: .7rem;
    font-weight: 600;
    position: absolute;
    bottom: 0;
    right: 0;
}

.list-media-img-duration-wrap {
    position: relative;
}

.media-component-timeclock-wrapper {
    position: relative;
    width: 100%;
}

@media only screen and (max-width: 834px) {
    .media-component-media-wrapper-list .media-cover-image {
        width: 300px;
        height: 166px;
        object-fit: cover;
        position: relative;
    }

    .media-component-wrapper-list {
        flex-direction: column;
    }
}

@media only screen and (max-width: 414px) {
    .media-component-media-wrapper-list .media-cover-image {
        object-fit: cover;
        position: relative;
    }
}
