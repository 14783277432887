.sortmodal-container {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 1rem;
    padding-right: 15px;
}

.sortmodal-text-icon-wrapper {
    display: flex;
}

.sortmodal-button-text {
    font-size: 12px;
    color: gray;
}

.sortmodal-icon-wrapper {
    height: 20px;
    width: 20px;
}

.sortmodal-list-icon-wrapper {
    height: 14px;
    width: 14px;
}

.sortmodal-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.sort-modal-body-container {
    position: absolute;
    background: white;
    z-index: 999999999;
    top: 40px;
    right: 8px;
    box-shadow: 2px 2px 5px 6px rgba(0, 0, 0, 0.24);
}

.sort-modal-body-container ul {
    list-style: none;
    /*margin-bottom: 0;*/
    margin: 0;
    padding: 0;
}

.sort-modal-body-container ul li {
    display: flex;
    justify-content: space-between;
    padding: 6px;
    font-family: 'Comfortaa', cursive;
    font-size: 12px;
}

.sort-modal-body-container ul li div {
    margin-right: 4px;
}
