.Spinner_Wrapper {
    text-align: center;
    padding-top: 10%;
}

.loading_header {
    padding-left: 0 !important;
    color: #00BAF2;
}

.lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #fff;
    border-color: #fff #BEBEBE #fff #BEBEBE;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
