.modal-body{
    text-align: center;
    font-weight: 550;
}


.delete {
    border-right: 1px solid black;
}

.delete-modal-footer {

    text-align: center;
    border-top: 1px solid black;
}

.delete-modal-footer .btn {
    color:rgba(21, 138, 208, 1);
    font-weight: 550;
}

.social-share-modal-content {
    font-size:14px ;
    font-family:'Comfortaa', cursive;
}
.share-modal-media-wrapper  {
    display: flex;
    align-items: center;
}

.share-modal-media-icon-wrapper {
    width: 100%;
    cursor: pointer;
}
.share-modal-media-icon-wrapper img {
    width: 90%;
    height: auto;
}

.social-share-modal-header {
    display:block;
    font-size: inherit;
    line-height: 2rem;
    padding:5px;
}
.header-row {
    padding-left: 5px;
}

.share-modal-body-footer {
    text-align:center;
    color:green;
    justify-content: center;
}

.input-wrapper {
    padding-left:20px;
}
@media only screen and (max-width: 414px) {
    .share-modal-body-wrapper .col-sm-2{
        display: inline-grid;
    }
    .header-row{
        flex-wrap: inherit;
    }
}

.share-modal-body-wrapper input {
    outline:none;
    border:hidden;
    text-align:center;
    width:100%;
    text-overflow:ellipsis;
}
.share-modal-body-wrapper .btn {
    font-size: 12px;
    font-weight: bold;
    padding-top:0;
}
