.channelbanner-container {
    height: 100%;
    width: 100%;
    position: relative;
}

.channelbanner-background-image {
    height: calc((100vw - 240px) / 6.2 - 1px);
    display: block;
    position: relative;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
}

.mobile-channelbanner-background-image {
    min-height: 16.5vw;
    display: block;
    position: relative;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
}

.channelbanner-placement-container {
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 999999;
}

.channelbanner-placement-container-fileuploader {
    position: absolute;
    z-index: 999999;
}

.channelbanner-display-text {
    font-family: 'Comfortaa', cursive;
    font-weight: 600;
}

.verified-icon-wrapper {
    height: 20px;
}

.verified-icon-wrapper img{
    height: 100%;
    width: auto;
}