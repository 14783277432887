/*ul, ul {*/
/*    list-style-position: inside;*/
/*    padding-left: 0;*/
/*}​*/

.nested-ul ul {
    list-style-position: inside;
    padding-left: 0;
}

.halfview-percentage-wrapper .reputation-modal-button {
    margin-right: .5rem;
}

.valid, .caution, .trimmed, .invalid {
    border-radius: 4px;
    font-weight: 600;
    font-size: .8rem;
    margin-right: 2px;
}

li {
    font-size: .8rem;
}

.bullet-wrapper {
    display: flex;
    font-family: 'Comfortaa', cursive;
    align-items: center;
    cursor: pointer;
}

.valid, .caution, .invalid, .trimmed {
    font-size: .7rem;
}

.valid {
    background: var(--vl_green);
}

.caution {
    background: var(--vl_yellow);
}

.invalid {
    background: var(--vl_red);
}

.trimmed {
    background: #bfbfbf;
}

.bullet-break {
    padding-right: 2px;

}

/*.remove-color {*/
/*    background: white;*/
/*}*/

.rep-score-desktop {
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;
    white-space: nowrap;
}

.rep-score-desktop-single {
    display: flex;
    width: 98%;
    justify-content: center;
    position: relative;
    padding-top: 1rem;
    white-space: nowrap;
}

.reputation-title {
    font-weight: bold;
    text-align: center;
    font-size: 1.5rem;
    padding-top: 1rem;
}

.reputation-modal-button {
    position: absolute;
    right: 0;
}

.reputation-modal-button img {
    margin-left: 1rem;
    width: 18px;
    color: white;
    position: relative;
    cursor: pointer;
}

.modal-container {
    position: fixed;
    background: white;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    /*min-height: 90vh;*/
    width: 75%;
    padding: 2rem;
    z-index: 99999999;
    border-radius: 15px;
    -webkit-box-shadow: 2px 2px 5px 9px rgba(0, 0, 0, 0.38);
    -moz-box-shadow: 2px 2px 5px 9px rgba(0, 0, 0, 0.38);
    box-shadow: 2px 2px 5px 9px rgba(0, 0, 0, 0.38);
}

.reputation-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
}

.top-header {
    text-align: center;
    color: white;
    font-weight: bold;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.reputation-card-wrapper {
    border: solid 1px grey;
    border-radius: 10px;
    background: lightgray;
    margin-bottom: 1rem;
}

.reputation-data-wrapper {
    color: black;
    font-weight: 500;
}

.reputation-close {
    position: absolute;
    top: 15px;
    right: 15px;
    font-weight: bold;
}

.reputation-close:hover {
    color: lightgray;
    cursor: pointer;
}

@media only screen and (max-width: 834px) {
    .reputation-grid {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 1rem;
    }

    .modal-container {
        height: 80vh;
        padding: 1rem;
        width: 90%;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .reputation-title {
        font-size: 1.25rem;
    }

    .reputation-data-wrapper {
        font-size: .8rem;
        font-weight: 400;
    }

    .reputation-data-wrapper-note {
        font-size: .8rem;
        font-weight: 400;
        padding-bottom: 1rem;
    }
}

@media only screen and (max-width: 414px) {
    .valid {
        background: var(--vl_green);
        text-align: center;
        min-width: 20vw;
    }

    .caution {
        background: var(--vl_yellow);
        text-align: center;
        min-width: 20vw;
    }

    .invalid {
        background: #ff4040;
        text-align: center;
        min-width: 20vw;
    }

    ul {
        padding-left: 26px;
        padding-right: 15px;
    }

    .trimmed {
        background: #bfbfbf;
        min-width: 20vw;
        text-align: center;
    }
}
