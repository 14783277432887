.encryption-container {
    background: #cb4c57;
    border-radius: 25px;
    padding: 15px;
    /*height: 100%;*/
    width: 95%;
    font-family: 'Comfortaa', cursive;
    font-size: .8rem;
}

.encryption-data-wrapper {
    /*display: flex;*/
    /*justify-content: space-between;*/
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: white;
}

.encryption-data {
    /*word-break: break-word;*/
    min-height: 1.2rem;
    word-break: break-all;
    font-size: .75rem;
}

.encryption-label {
    padding-right: 10px;
}

.component-icon img {
    /*width: 20%;*/
}

.encryption-component-icon img {
    width: 38px;
    height: 38px;
}

.encryption-component-icon, .component-icon{
    display: flex;
    justify-content: flex-end;
}