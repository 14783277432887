.half-media-playbar {
    /*height: 10vh;*/
    width: 100%;
    margin-left: -15px;
    position: absolute;
    justify-content: center;
    /*top: 50%;*/
    z-index: 999;
    padding-top: 14px;
}

.playbar-box-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    /*top: -56px;*/
    /*overflow-x: hidden;*/
    float: right;
}

.playbar-slider {
    -webkit-appearance: none;
    width: 100%;
    height: .25rem;
    background: #00bbff;
    outline: none;
    /*opacity: 0.7;*/
    -webkit-transition: .2s;
    transition: opacity .2s;
    z-index: 999999;
    position: fixed;
    top: 11vh;
    left: 0;
}

.playbar-duration-reason {
    font-family: 'Comfortaa', cursive;
    float: right;
    /*top: -58px;*/
    /*left: 111px;*/
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-bottom: 5px;
    white-space: pre;
    text-align: center;
    font-size: .8rem;
    border-radius: 4px;
    position: relative;
}

.playbar-slider-scrub {
    position: absolute;
    top: 0px;
    width: 100%;
}

.playbar-scrubber {
    -webkit-appearance: none;
    width: 100%;
    height: 2rem;
    z-index: 9999999;
    background: transparent;
}

.playbar-fake-bar {
    /*width: 100%;*/
    height: .25rem;
    background: black;
    position: absolute;
    top: .9rem;
}

.playbar-fake-progress {
    position: absolute;
    top: 0;
}

.playbar-scrubber::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 1.5rem;
    width: .5rem;
    border: none;
    /*border-radius: 50%;*/
    background: transparent;
    cursor: pointer;
}

.playbar-scrubber:focus {
    outline: none;
}

.playbar-progress {
    /*width: 100%;*/
    height: 4vh;
    position: absolute;
    top: 0;
}

.playbar-actual-progress {
    border-right: solid white 4px;
    height: .3rem;
    background: white;
    position: absolute;
    top: .9rem;
    z-index: 999999;
}

.playbar-actual-progress::before {
    content: '';
    position: absolute;
    right: -4px;
    top: -8px;
    width: 8px;
    height: 20px;
    background: white;
}


.playbar-duration-actual-progress {
    border-right: solid transparent 4px;
    height: .3rem;
    background: transparent;
    position: absolute;
    top: 0;
    min-width: 13%;
    max-width: 95%;
    z-index: 999999;
}

.playbar-duration-progress {
    max-width: 90%;
    height: 4vh;
    top: 0;
    position: absolute;
}

/*.half-view-frames-bar {*/
/*    height: 8vh;*/
/*    width: 100%;*/
/*    position: absolute;*/
/*    top: 0;*/
/*    background: rgb(131,58,180);*/
/*    background: linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 69%, rgba(252,176,69,1) 100%);*/
/*}*/

.playbar-mobile-subbar-image img {
    width: 100%;
    height: 2rem;
    top: 0;
    position: absolute;
    margin-right: -15px;
}

.playbar-duration-box {
    float: right;
    font-family: 'Comfortaa', cursive;
    text-align: center;
    background: white;
    /*top: -32px;*/
    /*left: 20px;*/
    padding: 2px;
    margin-bottom: 4px;
    min-width: 4rem;
    font-size: .8rem;
    border-radius: 4px;
    position: relative;
}

.summary-graph-data-bar-wrapper {
    margin-top: 1rem;
}

.summary-playbar-duration-box {
    /*float: right;*/
    background: white;
    font-family: 'Comfortaa', cursive;
    width: 100%;
    /*top: -32px;*/
    /*left: 18px;*/
    padding-top: 4px;
    margin-top: 5px;
    min-width: 4rem;
    font-size: .8rem;
    text-align: center;
    border-radius: 4px;
    position: relative;
}

.playbar-wrapper-group {
    position: absolute;
    /*display: flex;*/
    /*align-items: center;*/
    /*flex-direction: column;*/
}

.arrow-wrapper {
    display: flex;
    justify-content: center;
}

.desktop-media-container {
    height: 4vh;
    width: 100%;
    position: relative;
    justify-content: center;
    cursor: pointer;
    z-index: 999;
}

.bottom-arrow-centered {
    float: right;
    position: relative;
    width: 0;
    height: 0;
    top: -4px;
    border-style: solid;
    border-width: 7px 9px 0 9px;
    border-color: white transparent transparent transparent;
}

.collapsed-halfview-media-container {
    height: 4vh;
    width: 100%;
    position: absolute;
    justify-content: center;
    margin-left: -15px;
    z-index: 999;
    bottom: 1rem;
}