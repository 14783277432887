
.mobile-navbar-logo-wrapper img {
    width: 100%;
}

.hamburger-inner {
    background: white !important;
}

.hamburger-inner:after {
    background: white !important;
}

.hamburger-inner:before {
    background: white !important;
}

.form-control {
    border-radius: 0 !important;
    border-color: white !important;
}

.verified-modal-image-wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 10px;
}

.verified-modal-image-wrapper img {
    width: 5rem;
    margin-left: 4px;
    margin-right: 4px;
}

.verified-modal-content {
    font-size: .8rem;
    font-family: 'Comfortaa', cursive;
}

.verified-modal-container {
    position: absolute;
    top: 7vh;
    right: -50px;
    background-color: white;
    width: 350px;
    padding: 8px;
    -webkit-box-shadow: 4px 6px 24px -1px rgba(0, 0, 0, 0.63);
    -moz-box-shadow: 4px 6px 24px -1px rgba(0, 0, 0, 0.63);
    box-shadow: 4px 6px 24px -1px rgba(0, 0, 0, 0.63);
}

.verified-modal-container::after {
    content: '';
    position: absolute;
    right: 6px;
    top: -10px;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 17px solid white;
    clear: both;
}

.verified-modal-header {
    display: flex;
    align-items: center;
    padding-bottom: .5rem;
    font-weight: bold;
    font-family: 'Comfortaa', cursive;
}

.verified-modal-image-mobile {
    position: relative;
    width: 60px;
    padding-bottom: 5px;
}

.verified-modal-image-mobile img {
    width: 100% !important;
    padding-right: 2px;
    padding-left: 2px;
}

.verified-modal-mobile {
    position: absolute;
    top: 9vh;
    left: 15px;
    background-color: white;
    width: 75%;
    padding: 8px;
    -webkit-box-shadow: 4px 6px 24px -1px rgba(0, 0, 0, 0.63);
    -moz-box-shadow: 4px 6px 24px -1px rgba(0, 0, 0, 0.63);
    box-shadow: 4px 6px 24px -1px rgba(0, 0, 0, 0.63);
}

.verified-modal-mobile::after {
    content: '';
    position: absolute;
    right: 6px;
    top: -14px;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 17px solid white;
    clear: both;
}

.verified-media-icon-wrapper-image {
    width: 5rem;
    cursor: pointer;
    position: absolute;
    bottom: 25%;
}

.verified-media-icon-wrapper {
    position: relative;
}

.form-check-inline {
    min-width: 100px;
    display: flex;
    padding-bottom: 10px;
}

/*input[type="radio"] {*/
/*    width: 15px;*/
/*    height: 15px;*/
/*    border-radius: 15px;*/
/*    top: -2px;*/
/*    left: -1px;*/
/*    position: relative;*/
/*    background-color: #036ea9;*/
/*    content: '';*/
/*    display: inline-block;*/
/*    visibility: visible;*/
/*    border: 2px solid white;*/
/*}*/

/*input[type="radio"]:checked {*/
/*    background-color: #bbb;*/
/*}*/

.filter-component-wrapper {
    display: flex;
    align-items: center;
    min-width: 4.5rem;
}

.fa-angle-up, .fa-angle-down {
    cursor: pointer;
}

/*input[type='radio']:after {*/
/*    width: 15px;*/
/*    height: 15px;*/
/*    border-radius: 15px;*/
/*    top: -2px;*/
/*    left: -1px;*/
/*    position: relative;*/
/*    background-color: #036ea9;*/
/*    content: '';*/
/*    display: inline-block;*/
/*    visibility: visible;*/
/*    border: 2px solid white;*/
/*}*/

/*input[type='radio']:checked {*/
/*    width: 10px;*/
/*    height: 10px;*/
/*    border-radius: 10px;*/
/*    top: -2px;*/
/*    left: -1px;*/
/*    position: relative;*/
/*    background-color: red;*/
/*    visibility: visible;*/
/*    border: 2px solid white;*/
/*}*/


.navbar-radio-wrapper {
    display: flex;
    /*flex-direction: column;*/
    justify-content: flex-start;
    flex-wrap: wrap;
}

.filterOptionsHover {
    position: relative;
}

.filterOptions {
    background: rgb(21, 138, 208);
    background: linear-gradient(90deg, rgba(21, 138, 208, 1) 0%, rgba(16, 140, 177, 1) 100%, rgba(0, 0, 0, 1) 100%);
    z-index: 999;
    color: white;
    padding: 10px;
}

.navbar-drop-row {
    width: 100%;
    position: absolute;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border: solid white 0.2px;
    border-top: none;
}

.searchFilterButtons.active a {
    color: #00BAF2;
    font-weight: 400;
    text-decoration: none;
    border: none;
}

.searchFilterButtons a {
    text-decoration: none;
    color: white;
    font-weight: 400;
    font-size: 1.2rem;
    font-family: 'Comfortaa', cursive;
}

.searchFilterButtons:hover a {
    color: #00BAF2 !important;
}

.searchFilterButtons {
    background-color: transparent;
    border-radius: 0 !important;
}

/*.navbar-search-wrapper input:before {*/
/*    background: url("/icon-search.png");*/
/*    content: "\f007";*/
/*    position: relative;*/
/*}*/

.navbar-search-wrapper input {
    /* background-image: url("/icon-search.png"); */
    background-origin: content-box;
    background-repeat: no-repeat;
    background-position: right;
    padding: .2rem .2rem .2rem .2rem;
    background-size: 20px 20px;
    font-size: 1rem;
    line-height: 1.5;
    background-clip: padding-box;
    /*width: 100%;*/
}

.navbar-search-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    color: white;
    font-family: 'Comfortaa', cursive;
    font-size: .8rem;
    justify-content: space-around;
    align-items: center;
}

.filter-title {
    white-space: pre;
    font-size: 1rem;
    cursor: pointer;
    padding-right: .4rem;
}

.navbar-search-icon {
    position: absolute;
    right: 0;
}

.mainSearchFormInput {
    background-color: transparent;
    color: white;
    font-family: 'Comfortaa', cursive;
    border: 1px solid white;
}

.mainSearchFormInput::-webkit-input-placeholder {
    color: white;
}

.mainSearchFormInput:-moz-placeholder {
    color: white;
}

/*.mainSearchFormInput::-moz-p {*/
/*    color: red;*/
/*}*/

.mainSearchFormInput:-ms-input-placeholder {
    color: white;
}

/* Custom radio buttons */
/* input[type="radio"] + label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 30px;
    margin-right: 15px;
    font-size: 13px;
}

input[type="radio"] + label:before {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    margin-right: 14px;
    position: absolute;
    top: 2px;
    left: 0;
    border: 1px solid white;
    background-color: transparent;
    border-radius: 50%;
}

input[type="radio"] {
    display: none !important;
    *display: inline;
}

input[type="radio"]:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 6px;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
} */

.hide-opacity {
    visibility: hidden;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
}

.media-set {
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    visibility: visible;
}

.desktop-nav-user-icon-wrapper {
    height: 40px;
    width: 40px;
    cursor: pointer;
    position: relative;
}

.desktop-nav-user-icon-wrapper img {
    width: 100%;
}
