.youtube-search {
    border: solid 1px gray;
    width: 100%;
    padding: 4px;
    font-family: 'Comfortaa', cursive;
    line-height: 24px;
    font-size: 12px;
}

.youtube-search-container {
    width: 100%;
}

.youtube-search::placeholder {
    color: gray !important;
    font-size: .75rem;
}

.search-youtube-case {
    display: flex;
}

.search-button-youtube {
    border: solid 1px gray;
    background: silver;
    height: 34px;
    width: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 4px;
}

.search-button-youtube:hover {
    background: lightgray;
}

.youtube-search-icon-wrapper {
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.search-button-youtube img {
    width: 100%;
    height: 100%;
    contain: content;
}

