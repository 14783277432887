.sort-profile-verified-container {
    width: 100%;
}

.grid-media-nonlist-wrapper {
    padding-left: 2%;
    padding-right: 2%;
    display: flex;
    justify-content: center;
}

.grid-media-list-wrapper {
    padding-left: 12.5%;
    padding-right: 12.5%;
    /*display: flex;*/
    /*justify-content: center;*/
}

.grid-media-row {
    width: 100%;
}

.container-grid-wrapper {
    padding-top: 3rem;
}

.container-grid-wrapper h1 {
    padding-left: 12.5%;
    color: #00BAF2;
}

.top-sort-container {
    display: flex;
    justify-content: space-between;
    border-top: solid .5px lightgray;
    border-bottom: solid .5px lightgray;
    font-family: 'Comfortaa', cursive;
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

/*Set as the same size of video thumbnails to maintain consistent row*/

.option-0 {
    width: 168px;
}

.grid-media-row-list {
    /*width: 60%;*/
    display: flex;
    flex-direction: column;
}



@media only screen and (max-width: 834px) {
    .grid-media-row {
        width: 100%;
    }

    .grid-media-nonlist-wrapper {
        padding: 0;
    }

    .container-grid-wrapper h1 {
        text-align: center;
        color: #00BAF2;
        padding-left: 0;
    }
}

@media only screen and (max-width: 414px) {
    .grid-media-row-list {
        align-items: center;
    }
}