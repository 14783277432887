.connection-container {
    border-radius: 25px;
    background: #f36526;
    padding: 15px;
    height: 100%;
    width: 95%;
    font-family: 'Comfortaa', cursive;
    font-size: .8rem;
}

#gpshorizontalaccuracyCanvas, #dataTimeDelay, #connectionSpeed {
    z-index: 99;
}

.half-chart-background-gold {
    width: 100%;
    height: 50%;
    background: #959233;
    top: 0;
    position: absolute;
}

.signal-display-wrapper {
    height: 1rem;
    display: flex;
    border: solid black 1px;
    position: relative;
    width: 100%;
}

.signal-orange {
    height: 100%;
    background: orange;
}

.signal-white {
    height: 100%;
    background: white;
}

.signal-title {
    padding-right: 4px;
}

.signal-text {
    color: black;
    position: absolute;
    left: 40%;
    z-index: 999;
    font-size: .7rem;
}

.signal-container {
    display: flex;
}

.signal-container-overlay {
    position: absolute;
    height: 10px;
    width: 55px;
    top: -55px;
    right: -28px;
}

.signal-text-overlay {
    left: 20%;
    z-index: 999;
    font-size: .6rem;
    color: black;
    position: absolute;
}

.half-chart-background-green {
    width: 100%;
    height: 50%;
    bottom: 0;
    background: #4d723f;
    position: absolute;
}

.connection-speed-graph {
    border: solid 1px black;
    padding-right: 30px;
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.connection-component-icon {
    display: flex;
    justify-content: flex-end;
}

.connection-component-icon img {
    width: 46px;
    height: 34px;
}

.connection-speed-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    align-items: center;
}

.connection-speed-title {
    color: white;
    padding-bottom: .25rem;
}

.dot {
    height: 100%;
    border-right: solid 2px blue;
    position: absolute;
}

.progress-reveal {
    width: 100%;
    height: 100%;
    background: grey;
    z-index: 999;
    position: absolute;
}

.colorBrick {
    background: orange;
    height: 50%;
    width: 100%;
    position: absolute;
    top: 0;
}

.satellite-map-container {
    width: 100%;
    height: 100%;
    position: relative;
}

.location-map-wrapper {
    border: solid 1px black;
    background: white;
    height: 200px;
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.satellite-android {
    padding: 20px;
}

.satellite-data-group {
    padding-bottom: 1rem;
}

/*remove from time css*/
.time-sat-alt-wrapper {
    /* display: flex; */
    display: grid;
    grid-template-columns: auto;
    color: white;
    padding-bottom: 1rem;
}

.satellite-location-data {
    display: grid;
    color: white;
    font-size: .8rem;
    grid-template-columns: 1fr .5fr;
}

.satellite-row {
    width: 41%;
    font-weight: 700;
}

.satellite-wrapper {
    display: flex;
}

.satellite-map-title {
    text-align: center;
    color: white;
    padding-bottom: 1rem;
}

/*canvas {*/
/*    z-index: 999;*/
/*}*/