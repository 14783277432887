.mobile-footer-background {
    background: rgb(0,97,156);
    background: linear-gradient(90deg, rgba(0,97,156,1) 0%, rgba(16,140,177,1) 100%, rgba(0,0,0,1) 100%);
    color: white;
    width: 100%;
    text-align: center;
    padding: 10%;
}

.mobile-footer-social-icons {
    display: flex;
    padding-bottom: 2rem;
}

.mobile-footer-social-icon-background {
    padding-right: 10px;
}

.mobile-footer-social-icon-background i {
    width: 55px;
    font-size: 2rem;
    padding: 8px;
    margin-right: 14px;
    opacity: .7;
    color: #01a7e5;
    background: white;
}

.mobile-footer-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}

.mobile-footer-contact-button {
    font-weight: 600;
    font-size: 20px;
    padding: 10px;
    border: 2px solid white;
    letter-spacing: 1px;
}

.mobile-footer-address {
    padding-bottom: 2rem;
}

.mobile-footer-right-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.mobile-load-more-wrapper {
    background: lightgrey;
    text-align: center;
    margin-right: 5%;
    margin-left: 5%;
}

.mobile-load-more-text {
    color: white;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 8px;
    padding: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.mobile-footer-content-wrapper {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 9999;
}