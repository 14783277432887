.user-menu-wrapper {
    position: fixed;
    right: 20px;
    top: 100px;
    background: white;
    min-height: 35%;
    /*height: 100%;*/
    width: 400px;
    padding: 1rem;
    box-shadow: 2px 2px 5px 6px rgba(0, 0, 0, 0.24);
    z-index: 9999999;
}

.flex-center-wrapper, .align-center {
    display: flex;
    justify-content: center;
    padding-top: 1rem;
}

.align-center {
    font-family: 'Comfortaa', cursive;
    font-size: .8rem;
}

.confirm-message-box {
    font-family: 'Comfortaa', cursive;
    font-size: .8rem;
    text-align: center;
    padding-top: 1rem;
    width: 50%;
}

.user-menu-wrapper:after {
    content: '';
    position: absolute;
    right: 6px;
    top: -10px;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 17px solid white;
    clear: both;
}

.videolock-logo-blue-wrap {
    width: 260px;
}

.center-logo {
    display: flex;
    justify-content: center;
}

.back-arrow {
    cursor: pointer;
}

.videolock-logo-blue-wrap img {
    width: 100%;
    height: auto;
}

.videolock-title-text {
    color: var(--vl_lt_blue);
    font-size: 2.5rem;
    font-weight: 800;
    font-family: 'Comfortaa', cursive;
    letter-spacing: -1px;
}

.channel-input-wrapper {
    display: flex;
    justify-content: space-evenly;
    width: 85%;
}

.channel-input-wrapper input {
    /*width: 65% !important;*/
}

.logo-logotype-container {
    text-align: center;
    /*padding-top: 1rem;*/
}

.fa-arrow-left {
    cursor: pointer;
}

.relative-wrapper {
    height: 100%;
    position: relative;
}

.login-questions-wrapper {
    padding-top: 1rem;
    font-family: 'Comfortaa', cursive;
    font-size: .9rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
}

.login-questions-span {
    color: #00bbff;
    padding-left: 5px;
    cursor: pointer;
}

.form-space {
    height: 1rem;
}

.extra-form-space {
    height: 2.5rem;
}

.download-description {
    text-align: left;
    font-size: .9rem;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    font-family: 'Comfortaa', cursive;
}

.remove-styles a {
    text-decoration: none;
}

.remove-styles-btn {
    border: none;
}

.back-icon-wrapper {
    padding-bottom: 1.25rem;
}

.panel-message {
    color: black;
    font-family: 'Comfortaa', cursive;
    font-size: 1rem;
    text-align: center;
    padding-top: 8vh;
    padding-bottom: 10px;
}

.panel-message-wrapper-login {
    display: flex;
    justify-content: center;
}

.panel-message-login {
    color: black;
    font-family: 'Comfortaa', cursive;
    font-size: .95rem;
    text-align: left;
    padding-top: 8vh;
    padding-bottom: 10px;
    width: 85%;
}

.input-modal-type-search {
    outline: none;
    box-shadow: none !important;
    -webkit-appearance: none;
    border: var(--vl_grey) solid .5px;
}

.nav-message-box {
    font-family: 'Comfortaa', cursive;
    font-size: .8rem;
    text-align: center;
    padding-top: 1rem;
}

.panel-form-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.panel-form-input-container-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10vh;
}

.panel-form-input-container-login input {
    width: 85%;
    padding: 2px;
    text-align: center;
    border: solid lightgray .5px;
    font-family: 'Comfortaa', cursive;
}

.force-align-left {
    text-align: left !important;
}

.panel-form-input-container input {
    width: 85%;
    padding: 2px;
    text-align: center;
    border: solid lightgray .5px;
    font-family: 'Comfortaa', cursive;
    font-size: .9rem;
}

.forgot-password-wrapper {
    color: #00bbff;
    padding-top: 1rem;
    min-height: .5rem;
    text-align: center;
}

.login-questions-wrapper > div {
    padding-bottom: .5rem;
}

.navpop-up-blue-btn {
    color: white;
    background: var(--vl_lt_blue);
    font-family: 'Comfortaa', cursive;
    padding: 5px;
    width: 85%;
    font-size: 1rem;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
}

.navpop-up-blue-btn-create-account {
    color: white;
    background: var(--vl_lt_blue);
    font-family: 'Comfortaa', cursive;
    padding: 5px;
    width: 75%;
    font-size: 1rem;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
}

.forgot-password-wrapper div {
    cursor: pointer;
    font-family: 'Comfortaa', cursive;
    font-size: .8rem;
}

.button-container-center {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
}

.button-container-center div {
    margin-bottom: 1rem;
}

.password-error-wrapper {
    color: #dc3545;
    font-family: 'Comfortaa', cursive;
    font-size: .8rem;
    min-height: .8rem;
    text-align: left;
    margin-right: auto;
    padding-top: .5rem;
    padding-right: 7.5%;
    padding-left: 7.5%;
    padding-bottom: .7rem;
}

.password-change-message {
    font-family: 'Comfortaa', cursive;
    text-align: center;
    font-size: .8rem;
}

label {
    font-family: 'Comfortaa', cursive;
    font-size: .8rem;
}

.create-account-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
}

.icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.icon-wrapper div {
    padding-bottom: 1rem;
    font-family: 'Comfortaa', cursive;
    text-align: center;
    color: #036ea9;
    font-size: .9rem;
}

.icon-wrapper i {
    font-size: 4rem;
    padding-bottom: 1rem;
    color: #00bbff;
}


@media only screen and (max-width: 834px) {
    .user-menu-wrapper {
        position: fixed;
        top: 100px;
        background: white;
        height: 100%;
        right: 0;
        width: 100%;
        padding: 1rem;
        box-shadow: 2px 2px 5px 6px rgba(0, 0, 0, 0.24);
        z-index: 9999999;
    }
}

@media only screen and (max-width: 414px) {
    .user-menu-wrapper {
        top: 54px;
    }
}





