.private-grid-container {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 1px 1px;
    grid-template-areas: ". . . . . . . .";
    grid-gap: 10px;
    padding: 10px;
}

.private-visibility-wrapper {
    display: flex;
    align-items: baseline;
}

.visibility-image-wrapper {
    height: 20px;
    width: 20px;
    margin-right: 5px;
}

.visibility-image-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.media-share-title-uppercasefirst {
    font-family: 'Comfortaa', cursive;
    font-size: 12px;
}

.mobile-small-media-text {
    font-family: 'Comfortaa', cursive;
    font-size: 10px;
}

.media-share-title-uppercasefirst:first-letter, .mobile-small-media-text:first-letter {
    text-transform: capitalize;
}

.mobile-private-grid {
    margin-bottom: 20px;
}

.private-media-cover-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    /*max-height: 10vh;*/
}

.private-view-time-date {
    font-family: 'Comfortaa', cursive;
    font-size: 12px;
}

.private-media-cover-image {
    contain: content;
    width: 100%;
    height: 100%;
}

.private-media-wrapper {
    width: 168px;
    height: 93px;
    position: relative;
}

.mobile-private-media-wrapper {
    width: 120px;
    height: 90px;
    position: relative;
}

.private-reputation .bullet-wrapper {
    margin-bottom: 5px;
}

.private-edit-icon {
    text-align: right;
}

.mobile-private-container {
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    grid-template-rows: 1fr;
    gap: 1px 1px;
    grid-template-areas: ". . .";
    grid-gap: 10px;
    margin-bottom: 10px;
}

.listview-title-wrapper {
    font-family: 'Comfortaa', cursive;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.6rem;
    display: flex;
    justify-content: space-between;
}

.center-content-wrapper > div {
    margin-bottom: 5px;
}

.mobile-listview-title-wrapper {
    font-family: 'Comfortaa', cursive;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.6rem;
    display: flex;
    justify-content: space-between;
}

.image-relative-wrapper {
    position: relative;
}

.image-private-mobile-wrap {
    height: 90px;
    width: 160px;
}

.image-private-mobile-wrap img {
    contain: content;
    width: 100%;
    height: 100%;
}

.views-vis-container {
    display: flex;
}

@media all and (-ms-high-contrast: none) {
    .private-grid-container {
        display: -ms-grid;
        -ms-grid-columns: 1fr 2fr 1fr 1fr 1fr 1fr 1fr 2.5fr;
        -ms-grid-rows: 1fr;
    }

    /*8px breaks*/
}