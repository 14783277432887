.halfview-container {
    position: absolute;
    bottom: 0;
    z-index: 99;
    background: white;
    width: 100%;
    /*height: 100%;*/
    margin-left: -15px;
    display: flex;
    justify-content: center;
}

.half-view-drawer-container {
    /*max-height: 72vh !important;*/
    margin-top: calc(var(--vl_half_drawer_spacing) * 5);;
    overflow: scroll;
    max-width: 100%;
    height: 100%;
    overflow-x: hidden
}

.halfview-fullscreen-toggle {
    position: absolute;
    display: block;
    color: white;
    right: 15px;
    top: -55px;
    width: 20px;
}

.collapsed-fullscreen-toggle {
    position: absolute;
    z-index: 99;
    width: 20px;
    right: 20px;
    bottom: 18vh;
}

.collapsed-fullscreen-toggle img {
    width: 100%;
}

.halfview-fullscreen-toggle img{
    width: 100%;
}

.halfview-toggletab-wrapper {
    display: flex;
    position: absolute;
    justify-content: center;
    width: 85%;
    z-index: 999;
    top: calc(var(--vl_half_drawer_spacing) * 3);
}

.halfview-toggletab-wrapper > div {
    width: 100%;
    text-align: center;
    font-weight: 400;
    font-family: 'Comfortaa', cursive;
}

.up-triangle {
    width: 0;
    height: 0;
    border-style: solid;
    margin-bottom: .4rem;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent black transparent;
}

.up-triangle::after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: 11px;
    right: 0;
    border-bottom: solid grey 1px;
}

.down-triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: black transparent transparent transparent;
}

.tab-end-left {
    color: #02bbef;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-left: solid 1px #02bbef;
    border-top: solid 1px #02bbef;
    border-right: solid 1px #02bbef;
    border-bottom: solid 1px #02bbef;
}

.tab-end-right {
    color: #02bbef;
    border-left: solid 1px #02bbef;
    border-top: solid 1px #02bbef;
    border-right: solid 1px #02bbef;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom: solid 1px #02bbef;
}

.tab-middle {
    border-top: solid 1px #02bbef;
    border-bottom: solid 1px #02bbef;
    color: #02bbef;
}

.tab-middle.active, .tab-end-right.active, .tab-end-left.active {
    background: #02bbef;
    color: white;
}

.halfview-video-data-wrapper {
    padding: 0 35px 15px 35px;
    height: 100%;
    width: 100%;
    z-index: 9999;
    position: absolute;
    overflow: scroll;
    background: white;
    margin-top: calc(var(--vl_half_drawer_spacing) * 5);
}

.encryption-wrapper {
    width: 100%;
    position: absolute;
    z-index: 9999;
    background: white;
    overflow: scroll;
    margin-top: calc(var(--vl_half_drawer_spacing) * 5);
}

.halfview-percentage-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    position: absolute;
    top: calc(var(--vl_half_drawer_spacing) * 2);
}

.valid, .caution, .trimmed {
    border-radius: 4px;
    padding-right: 2px;
    padding-left: 2px;
    font-weight: 600;
}

.valid {
    background: var(--vl_green);
}

.caution {
    background: var(--vl_yellow);
}

.invalid {
    background: var(--vl_red);
}

.trimmed {
    background: var(--vl_grey);
}

.halfview-percentage-wrapper > div {
    /*margin-right: .5rem;*/
}

.half-view-subbar-image img {
    position: absolute;
    width: 100%;
    height: 1.5rem;
    top: calc(var(--vl_half_drawer_spacing) * 2);
    margin-right: -15px;
}

.half-view-views-date-wrapper {
    display: flex;
    color: gray;
    font-size: 12px;
    justify-content: left;
    white-space: pre;
}

.half-view-views-date-wrapper > div {
    padding-right: 5px;
}

@media screen and (max-width: 768px) {
    /*.user-menu-wrapper {*/
    /*    top: 50px !important;*/
    /*    width: 50% !important;*/
    /*    position: absolute;*/
    /*}*/
}

@media screen and (max-width: 834px)  and (orientation: landscape) {
    .halfview-video-data-wrapper {
        margin-top: 34vh;
    }

    .halfview-percentage-wrapper {
        top: 16vh;
    }

    .halfview-toggletab-wrapper {
        top: 24vh;
    }

    .halfview-container {
        height: 100%;
    }

    .encryption-wrapper {
    height: 100%;
    }

    .half-view-drawer-container {
        margin-top: 34vh;
    }

    .scale-wrapper {
        top: 8vh !important;
    }

    .encryption-wrapper {
        margin-top: 34vh;
    }

    .mobile-playbar {
        height: 18vh !important;
    }

    .user-menu-wrapper {
        top: 50px !important;
        width: 50% !important;
        position: absolute;
    }

    .collapsed-play-button {
        top: 55% !important;
    }
}