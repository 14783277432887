.location-container {
    background: #487386;
    border-radius: 25px;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    height: 100%;
    width: 95%;
}

.component-title {
    font-size: 1.4rem;
    color: white;
    font-weight: 600;
    font-family: 'Comfortaa', cursive;
}

.component-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.component-icon img {
    width: 25px;
    height: 35px;
}

.component-icon i {
    color: white;
    font-size: 2.5rem;
}

.changeBackground {
    background: red;
}

.location-address-wrapper div {
    color: white;
    min-height: 2rem;
    font-weight: 400;
    padding-bottom: .5rem;
    font-family: 'Comfortaa', cursive;
}

.map-marker-label {
    background-color: black;
}

.location-map-container {
    border: solid 1px black;
    background: white;
    height: 200px;
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.label-background {
    background: black;
    position: absolute;
    white-space: nowrap;
    left: -40px;
    bottom: -22px;
}

.label-text {
    /*font-weight: bold;*/
    color: white;
    padding: 2px;
}

.location-map {
    width: 100%;
    height: 100%;
    position: relative;
}

.location-compass-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    position: relative;
}

.compass-needle-outter-wrapper {
    position: absolute;
}

.compass-needle-inner-wrapper {
    position: relative;
}

/*.compass-needle-outter-inner {*/
/*    top: -88px;*/
/*    right: -13px;*/
/*    position: absolute;*/
/*}*/

.compass-needle-outter-inner {
    height: 140px;
    width: 25px;
    top: -88px;
    right: -14px;
    position: absolute;
}

.compass-needle-outter-inner img {
    width: 100%;
    height: 100%;
}

.compass-needle-north {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 70px solid red;
}

.compass-needle-south {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 70px solid blue;
}

.location-compass-wrapper .compass-back {
    width: 100%;
}

.location-compass-degrees {
    font-family: 'Comfortaa', cursive;
    font-size: .8rem;
    padding-top: 1rem;
}

.location-compass-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding-bottom: 1rem;
}

.reading-wrapper {
    text-align: center;
    font-family: 'Comfortaa', cursive;
    font-size: 1rem;
}

.reading-wrapper div {
    padding-bottom: 2px;
}

.reading-title {
    color: white;
    font-weight: 100;
    font-size: .9rem;
}

.reading {
    color: white;
    font-weight: 700;
    font-size: .8rem;
}

.location-graph-set {
    /*display: grid;*/
    /*grid-template-columns: 1fr 1fr 1fr;*/
    display: flex;
    justify-content: space-around;
    color: white;
    text-align: center;
}

.location-gps-accuracy-graph {
    border: solid 1px black;
    background: green;
    opacity: .7;
    margin-bottom: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.location-gps-accuracy-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
}

.location-gps-accuracy-reading, .location-gps-accuracy-title {
    padding-bottom: .5rem;
    color: white;
}

.location-graph {
    width: 33%;
}

#speedCanvas, #gpsaltitudeCanvas, #traveledCanvas {
    /*width: 100% !important;*/
}

.location-graph-data {
    font-size: .8rem;
    min-height: .8rem;
    padding-bottom: 10px;
    padding-top: 5px;
    font-weight: 600;
    font-family: 'Comfortaa', cursive;
}

.location-graph-title {
    font-size: .8rem;
    font-family: 'Comfortaa', cursive;
}

.marker {
    width: 14px;
    height: 14px;
    background: red;
    border-radius: 50%
}

@media only screen and (max-width: 414px) {
    .compass-needle-outter-inner {
        height: 100px;
        width: 19px;
        top: -68px;
        right: -12px;
    }
}

@media only screen and (max-width: 414px) {
    .compass-needle-outter-inner {
        height: 100px;
        width: 19px;
        top: -68px;
        right: -12px;
    }
}

