.profilecircle-container {
    position: relative;
}

.profilecircle-wrapper {
    -ms-flex: none;
    -webkit-flex: none;
    flex: none;
    border-radius: 50%;
    background-color: transparent;
    overflow: hidden;
}

.profilecircle-wrapper img {
    display: block;
    margin-left: var(--yt-img-margin-left, auto);
    margin-right: var(--yt-img-margin-right, auto);
    max-height: var(--yt-img-max-height, none);
    max-width: var(--yt-img-max-width, 100%);
    border-radius: var(--yt-img-border-radius, none);
}

.profilecircle-uploader-container {
    position: absolute;
    z-index: 999999;
    bottom: 0;
}