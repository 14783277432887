.toggle-container {
    width: 100%;
    padding-bottom: 1rem;
}

.networks-container {
    background: #bf4852;
    border-radius: 25px;
    padding: 15px;
    height: 100%;
    width: 95%;
    font-family: 'Comfortaa', cursive;
    font-size: .8rem;
}

.signal-text-wrapper {
    position: absolute;
    top: 0;
    left: 15%;
}

.toggle-triangle-up {
    width: 0;
    height: 0;
    cursor: pointer;
    border-style: solid;
    border-width: 0 15px 15px 15px;
    border-color: transparent transparent white transparent;
    margin-right: .8rem;
}

.networks-component-icon img {
    width: 45px;
}

.labels-row {
    font-weight: 100;
    font-size: .8rem;
}

.toggle-triangle-down {
    width: 0;
    height: 0;
    cursor: pointer;
    border-style: solid;
    border-width: 15px 15px 0 15px;
    margin-right: .8rem;
    border-color: white transparent transparent transparent;
}

.toggle-controls-wrapper {
    display: flex;
    color: white;
    font-size: .8rem;
    align-items: center;
    padding-bottom: 1rem;
}

.details-title {
    cursor: pointer;
}

.toggle-content-wrapper {

}

.internet-date-time-wrapper {
    display: flex;
    justify-content: center;
    font-size: .8rem;
    color: white;
    padding-bottom: .25rem;
}

.trace-grid-data {
    font-size: .7rem;
    display: grid;
    color: white;
    grid-template-columns: .75fr 1fr 1fr;
}

.trace-grid-container {
    font-size: .8rem;
    border-bottom: solid white 1px;
    margin-bottom: .5rem;
}

.trace-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    color: white;
    /*padding-bottom: .25rem;*/
}

.hop {
    padding-left: 10px;
}