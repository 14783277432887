
.custom-input-wrapper {
    position: relative;
    /*margin-right: 5px;*/
    margin-bottom: 10px;
    border: 1px solid #D3D3D3;
    /*width:100%;*/
    /*height:100%*/
}

.custom-input-wrapper:focus-within {
    border: 2px solid rgb(86, 180, 239);
    color: rgb(86, 180, 239)
}

.custom-input-wrapper textarea {
    width: 100%;
    font-family: 'Comfortaa', cursive;
    font-weight: bold;
    font-size: 16px;
    border-style: none;
    border-color: Transparent;
    padding-top: 2rem;
    resize: none;
}

.custom-input-wrapper textarea:focus {
    outline: none;

}

.placeholder-span {
    position: absolute;
    padding-left: 5px;
    font-family: 'Comfortaa', cursive;
    font-size: 12px;
}

.char-count-span {
    position: absolute;
    /*float:right;*/
    right: 4px;
    bottom: 2px;
    color: lightgray;
    font-family: 'Comfortaa', cursive;
    font-size: 10px;
}

