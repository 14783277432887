.time-container {
    background: #2b8a84;
    border-radius: 25px;
    padding: 15px;
    height: 100%;
    width: 95%;
}

.time-clocks-wrapper {
    display: flex;
    justify-content: space-around;
}

.time-clocks-wrapper div {
    font-family: 'Comfortaa', cursive;
    font-size: .8rem;
}

.time-clock {
    padding-bottom: 1rem;
}

.time-component-icon img {
    width: 42px;
    height: 42px;
}

.time-component-icon {
    display: flex;
    justify-content: flex-end;
}

.react-clock__face {
    border: 1px solid #f8f9fa;
}

.react-clock__mark__body, .react-clock__hand__body, .react-clock__minute-hand {
    background: white;
}

.react-clock__mark__body.dark, .react-clock__hand__body.dark, .react-clock__minute-hand.dark {
    background: black;
}

.react-clock__second-hand__body {
    background: red;
}

.time-clock-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    padding-bottom: 1rem;
}

.time-clocks-title {
    font-size: 1rem;
    font-weight: 600;
    padding-bottom: .5rem;
}

.time-clock-time, .time-clock-date {
    font-weight: 600;
    font-size: 1rem;
}

.time-daylight-icon {
    height: 20px;
}

.time-daylight-icon img {
    height: 100%;
}

.time-daylight-map-wrapper {
    border: solid 1px black;
    background: white;
    opacity: .7;
    /*height: 200px;*/
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.time-daylight-reading-wrapper {
    color: white;
    display: flex;
    justify-content: space-between;
    font-family: 'Comfortaa', cursive;
    font-size: .8rem;
}

.time-daylight-readings-wrapper {
    padding-bottom: 1rem;
}

.time-sat-alt-wrapper {
    /* display: flex; */
    display: grid;
    grid-template-columns: auto auto auto;
    color: white;
}

.time-sat-alt-data {
    font-weight: 600;
    padding-left: .25rem;
}

.time-daylight-sat-alt {
    color: white;
}

.time-daylight-map img {
    height: auto;
    max-width: 100%;
    display: block;
}
