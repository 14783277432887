.mainFooterNavBackground {
    background: rgb(0, 97, 156);
    background: linear-gradient(90deg, rgba(0, 97, 156, 1) 0%, rgba(16, 140, 177, 1) 100%, rgba(0, 0, 0, 1) 100%);
    color: white;
    margin-right: 5%;
    margin-left: 5%;
    padding: 3%;
}

.footer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.footer-contact-button {
    font-weight: 600;
    font-size: 20px;
    padding: 10px;
    border: 2px solid white;
    letter-spacing: 1px;
}

.terms-wrapper {
    display: flex;
    font-size: .8rem;
    justify-content: left;
}

.terms-wrapper > div {
     padding-right: 4px;
 }

.terms-wrapper > a {
    padding-right: 4px;
}

.footer-address {

}

.footer-right-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center
}

.footer-social-icon-background {
    padding-right: 10px;
}

.footer-social-icon-background img {
    opacity: .7;
}

.footer-social-icon-background img:hover {
    opacity: 1;
}


.load-more-wrapper {
    background: lightgrey;
    text-align: center;
    margin-right: 5%;
    margin-left: 5%;
}

.load-more-text {
    color: white;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 8px;
    padding: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.footer-content-wrapper {
    /*position: fixed;*/
    left: 0;
    bottom: -200px;
    width: 100%;
    position: relative;
}

a {
    text-decoration: none;
    color: white;
}

a:hover {
    color: white;
    text-decoration: none;
}