.user-modal-body-container {
    position: fixed;
    background: white;
    z-index: 999999999;
    top: 64px;
    right: 1%;
    box-shadow: 2px 2px 5px 6px rgba(0, 0, 0, 0.24);
}

.user-modal-list-icon-wrapper {
    height: 14px;
    width: 14px;
}

.user-modal-body-container ul {
    list-style: none;
    /*margin-bottom: 0;*/
    margin: 0;
    padding: 0;
}

.user-modal-body-container ul li {
    display: flex;
    padding: 6px;
    font-family: 'Comfortaa', cursive;
    font-size: 16px;
    cursor: pointer;
}

.profile-box {
    display: flex;
    align-items: center;
    padding: 6px;
    border-bottom: solid .5px lightgrey ;
}

.banner-email {
    font-family: 'Comfortaa', cursive;
    font-size: 12px;
}

.user-modal-body-container ul li div {
    margin-right: 14px;
}

@media only screen and (max-width: 834px) {
    .user-modal-body-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        z-index: 999999999999999999;
    }

    .user-modal-body-container ul {
        width: 100%;
    }

    .close-svg {
        width: 25px;
        height: 25px;
        padding-right: 10px;
    }

    .close-header {
        width: 100%;
        display: flex;
        padding: 10px;
        font-size: 16px;
        border-bottom: solid .5px lightgray;
    }


}

