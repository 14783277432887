audio {
    width: 100%;
}

.reason-wrapper {
    animation: pulse 4s ease-in-out infinite;
    font-family: 'Comfortaa', cursive;
}

.transparent-click-zone {
    position: fixed;
    margin-left: -15px;
    width: 100%;
    height: 100%;
    z-index: 8;
}

.media-description {
    font-size: .8rem;
    font-family: 'Comfortaa', cursive;
}

.fullscreen-desktop-media-container {
    position: relative;
    width: 100%;
    justify-content: center;
    z-index: 9999;
    height: 4vh;
}

.playbar-wrapper-full {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 89%;
    z-index: 99999;
    margin-left: -15px;
}

#half-view-button {
    color: white;
    position: absolute;
    z-index: 999;
    top: 25%;
    left: 47%;
    font-size: 4rem;
    cursor: pointer;
}

#fixed-center-play {
    position: fixed;
    top: 48%;
    right: 48%;
    color: white;
    cursor: pointer;
    font-size: 4rem;
}

.video-react-big-play-button {
    visibility: hidden;
}

.caution-reason-wrapper {
    animation: pulse 4s ease-in-out infinite;
    display: flex;
    justify-content: space-between;
    font-size: .8rem;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
    width: 100%;
}

.collapsed-play-button {
    color: white;
    z-index: 9;
    position: absolute;
    top: 35%;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: -15px;
}

.collapsed-play-button i {
    font-size: 4rem;
}

.caution-readout {
    display: flex;
    align-items: center;
}

.fullscreen-toggle img {
    width: 100%;
}

@keyframes pulse {
    0% {
        color: white;
        opacity: 0;
    }
    50% {
        color: white;
        opacity: 1;
    }
    100% {
        color: white;
        opacity: 0;
    }
}

.desktop-media-single-image-wrapper {
    background: black;
}

.desktop-media-single-image-wrapper-full {
    height: 87vh !important;
    margin-right: -15px;
    margin-left: -15px;
    background: black;
}

.desktop-media-single-image-wrapper-full img {
    height: 100%;
    image-orientation: from-image;
}

.desktop-media-single-image-wrapper img {
    height: 100%;
}

.media-single-image-wrapper img {
    width: 100%;
}

.media-single-image-wrapper {
    position: absolute;
    background: black;
    height: 100%;
    margin-right: -15px;
}

.nopadding {
    padding: 0 !important;
    margin: 0 !important;
}

.mobile-chevron {
    visibility: hidden;
}

.mobile-media {
    position: absolute;
    height: 93vh !important;
    z-index: 1;
    margin-left: -15px;
}

.mobile-media-half {
    height: 44vh;
    margin-left: -15px;
}

.mobile-drawer {
    z-index: 2;
}

.slidecontainer {
    display: none;
}

.desktop-play-bar {
    width: 100%;
    height: 4vh;
}

.desktop-play-bar img {
    height: 2.5rem;
    width: 100%;
}

.desktop-center-line {
    height: .25rem;
    background: black;
    z-index: 9999;
    bottom: 23px;
    position: relative;
}

.progress-bar-desktop {
    border-right: solid white 4px;
    height: 2.5rem;
    position: relative;
    bottom: 44px;
    z-index: 999999;
}

.progress-bar-audio-desktop {
    border-right: solid white 2px;
    height: 2.5rem;
    position: relative;
    bottom: 17%;
    z-index: 999999;
}

.media-single-details-wrapper {
    /*padding: 1rem;*/
}

.waveform-wrapper .wave-background {
    background: black;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 480px;
}

.waveform-wrapper i {
    color: white;
    font-size: 2.5rem;
    padding-top: 4rem;
    padding-bottom: 2rem;
}

.fullscreen-toggle {
    display: none;
}

.scale-wrapper {
    background: transparent;
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    top: 4vh;
    z-index: 99999999999999999999;
}

.scale-wrapper i {
    font-size: 2rem;
    color: red;
}

.media-single-verification-bar-yellow {
    display: flex;
    font-size: 1rem;
    font-family: 'Comfortaa', cursive;
    justify-content: left;
    align-items: center;
    color: white;
    padding-top: 10px;
    padding-bottom: 5px;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 999;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    animation: yellowpulse 2.2s infinite;
}

.media-single-verification-bar-yellow i {
    padding-right: 5px;
    padding-left: 5px;
}

.media-single-verification-bar-green {
    display: flex;
    padding-right: 6vw;
    font-size: 1rem;
    justify-content: flex-end;
    align-items: center;
    color: white;
    padding-top: 10px;
    padding-bottom: 5px;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 999;
    will-change: transform, opacity;
    /*background: linear-gradient(90deg, rgba(40, 167, 69,.6) 10%, rgba(113,156,51,0) 0%);*/
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: ease-in-out;
    animation: greenpulse 2.2s infinite;
    background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(51, 156, 91, 1) 50%, rgba(0, 0, 0, 0) 100%);
}

@keyframes yellowpulse {
    0% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 0%, rgba(0, 0, 0, 0) 100%);
    }
    2% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 2%, rgba(0, 0, 0, 0) 100%);
    }
    3% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 3%, rgba(0, 0, 0, 0) 100%);
    }
    5% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 5%, rgba(0, 0, 0, 0) 100%);
    }
    7% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 7%, rgba(0, 0, 0, 0) 100%);
    }
    8% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 8%, rgba(0, 0, 0, 0) 100%);
    }
    10% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 10%, rgba(0, 0, 0, 0) 100%);
    }
    12% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 10%, rgba(0, 0, 0, 0) 100%);
    }
    13% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 13%, rgba(0, 0, 0, 0) 100%);
    }
    15% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 15%, rgba(0, 0, 0, 0) 100%);
    }
    17% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 17%, rgba(0, 0, 0, 0) 100%);
    }
    18% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 18%, rgba(0, 0, 0, 0) 100%);
    }
    20% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 20%, rgba(0, 0, 0, 0) 100%);
    }
    22% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 22%, rgba(0, 0, 0, 0) 100%);
    }
    23% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 23%, rgba(0, 0, 0, 0) 100%);
    }
    25% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 25%, rgba(0, 0, 0, 0) 100%);
    }
    27% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 27%, rgba(0, 0, 0, 0) 100%);
    }
    28% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 28%, rgba(0, 0, 0, 0) 100%);
    }
    30% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 30%, rgba(0, 0, 0, 0) 100%);
    }
    32% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 32%, rgba(0, 0, 0, 0) 100%);
    }
    33% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 33%, rgba(0, 0, 0, 0) 100%);
    }
    35% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 35%, rgba(0, 0, 0, 0) 100%);
    }
    37% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 37%, rgba(0, 0, 0, 0) 100%);
    }
    38% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 38%, rgba(0, 0, 0, 0) 100%);
    }
    40% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 40%, rgba(0, 0, 0, 0) 100%);
    }
    42% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 42%, rgba(0, 0, 0, 0) 100%);
    }
    43% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 43%, rgba(0, 0, 0, 0) 100%);
    }
    45% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 45%, rgba(0, 0, 0, 0) 100%);
    }
    47% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 47%, rgba(0, 0, 0, 0) 100%);
    }
    48% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 48%, rgba(0, 0, 0, 0) 100%);
    }
    50% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 50%, rgba(0, 0, 0, 0) 100%);
    }
    52% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 52%, rgba(0, 0, 0, 0) 100%);
    }
    53% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 53%, rgba(0, 0, 0, 0) 100%);
    }
    55% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 55%, rgba(0, 0, 0, 0) 100%);
    }
    57% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 57%, rgba(0, 0, 0, 0) 100%);
    }
    58% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 58%, rgba(0, 0, 0, 0) 100%);
    }
    60% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 60%, rgba(0, 0, 0, 0) 100%);
    }
    62% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 62%, rgba(0, 0, 0, 0) 100%);
    }
    63% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 63%, rgba(0, 0, 0, 0) 100%);
    }
    65% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 65%, rgba(0, 0, 0, 0) 100%);
    }
    67% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 67%, rgba(0, 0, 0, 0) 100%);
    }
    68% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 68%, rgba(0, 0, 0, 0) 100%);
    }
    70% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 70%, rgba(0, 0, 0, 0) 100%);
    }
    72% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 72%, rgba(0, 0, 0, 0) 100%);
    }
    73% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 73%, rgba(0, 0, 0, 0) 100%);
    }
    75% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 75%, rgba(0, 0, 0, 0) 100%);
    }
    76% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 76%, rgba(0, 0, 0, 0) 100%);
    }
    77% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 77%, rgba(0, 0, 0, 0) 100%);
    }
    80% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 80%, rgba(0, 0, 0, 0) 100%);
    }
    82% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 82%, rgba(0, 0, 0, 0) 100%);
    }
    83% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 83%, rgba(0, 0, 0, 0) 100%);
    }
    85% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 85%, rgba(0, 0, 0, 0) 100%);
    }
    86% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 86%, rgba(0, 0, 0, 0) 100%);
    }
    87% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 87%, rgba(0, 0, 0, 0) 100%);
    }
    90% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 90%, rgba(0, 0, 0, 0) 100%);
    }
    92% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 92%, rgba(0, 0, 0, 0) 100%);
    }
    93% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 93%, rgba(0, 0, 0, 0) 100%);
    }
    95% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 95%, rgba(0, 0, 0, 0) 100%);
    }
    96% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 96%, rgba(0, 0, 0, 0) 100%);
    }
    97% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 97%, rgba(0, 0, 0, 0) 100%);
    }
    98% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 98%, rgba(0, 0, 0, 0) 100%);
    }
    100% {
        background: linear-gradient(90deg, rgba(51, 156, 91, 0) 0%, rgba(244, 219, 63, 1) 100%, rgba(0, 0, 0, 0) 100%);
    }
}

@keyframes greenpulse {
    0% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 0%, rgba(0, 0, 0, 0) 100%);
    }
    2% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 2%, rgba(0, 0, 0, 0) 100%);
    }
    3% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 3%, rgba(0, 0, 0, 0) 100%);
    }
    5% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 5%, rgba(0, 0, 0, 0) 100%);
    }
    7% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 7%, rgba(0, 0, 0, 0) 100%);
    }
    8% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 8%, rgba(0, 0, 0, 0) 100%);
    }
    10% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 10%, rgba(0, 0, 0, 0) 100%);
    }
    12% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 12%, rgba(0, 0, 0, 0) 100%);
    }
    13% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 13%, rgba(0, 0, 0, 0) 100%);
    }
    15% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 15%, rgba(0, 0, 0, 0) 100%);
    }
    16% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 16%, rgba(0, 0, 0, 0) 100%);
    }
    17% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 17%, rgba(0, 0, 0, 0) 100%);
    }
    18% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 18%, rgba(0, 0, 0, 0) 100%);
    }
    20% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 20%, rgba(0, 0, 0, 0) 100%);
    }
    22% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 22%, rgba(0, 0, 0, 0) 100%);
    }
    23% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 23%, rgba(0, 0, 0, 0) 100%);
    }
    25% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 25%, rgba(0, 0, 0, 0) 100%);
    }
    27% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 27%, rgba(0, 0, 0, 0) 100%);
    }
    29% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 29%, rgba(0, 0, 0, 0) 100%);
    }
    30% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 30%, rgba(0, 0, 0, 0) 100%);
    }
    32% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 32%, rgba(0, 0, 0, 0) 100%);
    }
    33% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 33%, rgba(0, 0, 0, 0) 100%);
    }
    35% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 35%, rgba(0, 0, 0, 0) 100%);
    }
    36% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 36%, rgba(0, 0, 0, 0) 100%);
    }
    37% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 37%, rgba(0, 0, 0, 0) 100%);
    }
    39% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 39%, rgba(0, 0, 0, 0) 100%);
    }
    40% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 40%, rgba(0, 0, 0, 0) 100%);
    }
    42% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 42%, rgba(0, 0, 0, 0) 100%);
    }
    43% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 43%, rgba(0, 0, 0, 0) 100%);
    }
    45% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 45%, rgba(0, 0, 0, 0) 100%);
    }
    46% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 46%, rgba(0, 0, 0, 0) 100%);
    }
    47% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 47%, rgba(0, 0, 0, 0) 100%);
    }
    49% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 49%, rgba(0, 0, 0, 0) 100%);
    }
    50% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 50%, rgba(0, 0, 0, 0) 100%);
    }
    52% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 52%, rgba(0, 0, 0, 0) 100%);
    }
    53% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 53%, rgba(0, 0, 0, 0) 100%);
    }
    55% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 55%, rgba(0, 0, 0, 0) 100%);
    }
    56% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 56%, rgba(0, 0, 0, 0) 100%);
    }
    57% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 57%, rgba(0, 0, 0, 0) 100%);
    }
    59% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 59%, rgba(0, 0, 0, 0) 100%);
    }
    60% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 60%, rgba(0, 0, 0, 0) 100%);
    }
    62% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 62%, rgba(0, 0, 0, 0) 100%);
    }
    63% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 63%, rgba(0, 0, 0, 0) 100%);
    }
    65% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 65%, rgba(0, 0, 0, 0) 100%);
    }
    66% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 66%, rgba(0, 0, 0, 0) 100%);
    }
    67% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 67%, rgba(0, 0, 0, 0) 100%);
    }
    69% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 69%, rgba(0, 0, 0, 0) 100%);
    }
    70% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 70%, rgba(0, 0, 0, 0) 100%);
    }
    72% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 72%, rgba(0, 0, 0, 0) 100%);
    }
    73% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 73%, rgba(0, 0, 0, 0) 100%);
    }
    75% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 75%, rgba(0, 0, 0, 0) 100%);
    }
    77% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 77%, rgba(0, 0, 0, 0) 100%);
    }
    79% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 77%, rgba(0, 0, 0, 0) 100%);
    }
    80% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 80%, rgba(0, 0, 0, 0) 100%);
    }
    82% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 82%, rgba(0, 0, 0, 0) 100%);
    }
    83% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 83%, rgba(0, 0, 0, 0) 100%);
    }
    85% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 85%, rgba(0, 0, 0, 0) 100%);
    }
    87% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 87%, rgba(0, 0, 0, 0) 100%);
    }
    88% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 88%, rgba(0, 0, 0, 0) 100%);
    }
    90% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 90%, rgba(0, 0, 0, 0) 100%);
    }
    92% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 92%, rgba(0, 0, 0, 0) 100%);
    }
    93% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 93%, rgba(0, 0, 0, 0) 100%);
    }
    95% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 95%, rgba(0, 0, 0, 0) 100%);
    }
    96% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 96%, rgba(0, 0, 0, 0) 100%);
    }
    97% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 97%, rgba(0, 0, 0, 0) 100%);
    }
    100% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(140, 181, 104, 1) 100%, rgba(0, 0, 0, 0) 100%);
    }
}

@keyframes redpulse {
    0% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 0%, rgba(0, 0, 0, 0) 100%);
    }
    2% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 2%, rgba(0, 0, 0, 0) 100%);
    }
    3% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 3%, rgba(0, 0, 0, 0) 100%);
    }
    5% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 5%, rgba(0, 0, 0, 0) 100%);
    }
    7% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 7%, rgba(0, 0, 0, 0) 100%);
    }
    8% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 8%, rgba(0, 0, 0, 0) 100%);
    }
    10% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 10%, rgba(0, 0, 0, 0) 100%);
    }
    12% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 12%, rgba(0, 0, 0, 0) 100%);
    }
    13% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 13%, rgba(0, 0, 0, 0) 100%);
    }
    15% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 15%, rgba(0, 0, 0, 0) 100%);
    }
    16% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 16%, rgba(0, 0, 0, 0) 100%);
    }
    17% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 17%, rgba(0, 0, 0, 0) 100%);
    }
    18% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 18%, rgba(0, 0, 0, 0) 100%);
    }
    20% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 20%, rgba(0, 0, 0, 0) 100%);
    }
    22% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 22%, rgba(0, 0, 0, 0) 100%);
    }
    23% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 23%, rgba(0, 0, 0, 0) 100%);
    }
    25% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 25%, rgba(0, 0, 0, 0) 100%);
    }
    27% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 27%, rgba(0, 0, 0, 0) 100%);
    }
    29% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 29%, rgba(0, 0, 0, 0) 100%);
    }
    30% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 30%, rgba(0, 0, 0, 0) 100%);
    }
    32% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 32%, rgba(0, 0, 0, 0) 100%);
    }
    33% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 33%, rgba(0, 0, 0, 0) 100%);
    }
    35% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 35%, rgba(0, 0, 0, 0) 100%);
    }
    36% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 36%, rgba(0, 0, 0, 0) 100%);
    }
    37% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 37%, rgba(0, 0, 0, 0) 100%);
    }
    39% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 39%, rgba(0, 0, 0, 0) 100%);
    }
    40% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 40%, rgba(0, 0, 0, 0) 100%);
    }
    42% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 42%, rgba(0, 0, 0, 0) 100%);
    }
    43% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 43%, rgba(0, 0, 0, 0) 100%);
    }
    45% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 45%, rgba(0, 0, 0, 0) 100%);
    }
    46% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 46%, rgba(0, 0, 0, 0) 100%);
    }
    47% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 47%, rgba(0, 0, 0, 0) 100%);
    }
    49% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 49%, rgba(0, 0, 0, 0) 100%);
    }
    50% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 50%, rgba(0, 0, 0, 0) 100%);
    }
    52% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 52%, rgba(0, 0, 0, 0) 100%);
    }
    53% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 53%, rgba(0, 0, 0, 0) 100%);
    }
    55% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 55%, rgba(0, 0, 0, 0) 100%);
    }
    56% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 56%, rgba(0, 0, 0, 0) 100%);
    }
    57% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 57%, rgba(0, 0, 0, 0) 100%);
    }
    59% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 59%, rgba(0, 0, 0, 0) 100%);
    }
    60% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 60%, rgba(0, 0, 0, 0) 100%);
    }
    62% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 62%, rgba(0, 0, 0, 0) 100%);
    }
    63% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 63%, rgba(0, 0, 0, 0) 100%);
    }
    65% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 65%, rgba(0, 0, 0, 0) 100%);
    }
    66% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 66%, rgba(0, 0, 0, 0) 100%);
    }
    67% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 67%, rgba(0, 0, 0, 0) 100%);
    }
    69% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 69%, rgba(0, 0, 0, 0) 100%);
    }
    70% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 70%, rgba(0, 0, 0, 0) 100%);
    }
    72% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 72%, rgba(0, 0, 0, 0) 100%);
    }
    73% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 73%, rgba(0, 0, 0, 0) 100%);
    }
    75% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 75%, rgba(0, 0, 0, 0) 100%);
    }
    77% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 77%, rgba(0, 0, 0, 0) 100%);
    }
    79% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 77%, rgba(0, 0, 0, 0) 100%);
    }
    80% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 80%, rgba(0, 0, 0, 0) 100%);
    }
    82% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 82%, rgba(0, 0, 0, 0) 100%);
    }
    83% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 83%, rgba(0, 0, 0, 0) 100%);
    }
    85% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 85%, rgba(0, 0, 0, 0) 100%);
    }
    87% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 87%, rgba(0, 0, 0, 0) 100%);
    }
    88% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 88%, rgba(0, 0, 0, 0) 100%);
    }
    90% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 90%, rgba(0, 0, 0, 0) 100%);
    }
    92% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 92%, rgba(0, 0, 0, 0) 100%);
    }
    93% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 93%, rgba(0, 0, 0, 0) 100%);
    }
    95% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 95%, rgba(0, 0, 0, 0) 100%);
    }
    96% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 96%, rgba(0, 0, 0, 0) 100%);
    }
    97% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 97%, rgba(0, 0, 0, 0) 100%);
    }
    100% {
        background: linear-gradient(90deg, rgba(75, 217, 100, 0) 0%, rgba(255, 64, 64, 1) 100%, rgba(0, 0, 0, 0) 100%);
    }
}

.media-single-video-details {
    padding: 1rem;
}

.sliderIsOff {
    display: none;
}

.sliderIsOn {
    display: block;
    margin-bottom: 1vh;
    margin-top: 1vh;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: .25rem;
    background: #00bbff;
    outline: none;
    /*opacity: 0.7;*/
    -webkit-transition: .2s;
    transition: opacity .2s;
    z-index: 999999;
    /*position: fixed;*/
    top: 11vh;
    left: 0;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background: #bbbbbb;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #4CAF50;
    cursor: pointer;
}

.full-desk-chev {
    z-index: 999999999;
    font-size: 3rem;
    color: #02bbef;
    position: absolute;
    left: 30px;
    top: 35%;
}

.playbar-full-container {
    background: white;
    opacity: .9;
    height: 100%;
}

.full-desk-chev-left {
    z-index: 999999999;
    font-size: 3rem;
    color: #02bbef;
    position: absolute;
    right: 30px;
    top: 35%;
}

.drawerShown {
    display: block;
}

.drawerHidden {
    display: none;
}

.play-bar-data-wrapper i {
    font-size: 1.5rem;
    cursor: pointer;
}

.play-bar-data-wrapper {
    display: flex;
    color: #036ea9;
    justify-content: space-around;
    font-size: .8rem;
    padding: 10px;
}

@media only screen and (max-width: 834px) {
    .mobile-chevron {
        visibility: visible;
        font-size: 2.5rem;
        color: #02bbef;
        position: fixed;
        z-index: 999;
        top: 50%;
        left: 6%;
    }

    .mobile-scrub-playcontrols {
        width: 100%;
        position: absolute;
        justify-content: center;
        z-index: 999;
        top: -20px;
        padding-top: 14px;
    }

    .sliderIsOn {
        margin-bottom: 0;
    }

    .terms-wrapper {
        justify-content: center;
    }

    .media-single-image-wrapper img {
        /*position: absolute;*/
    }

    .mobile-chevron-left {
        visibility: visible;
        font-size: 2.5rem;
        color: #02bbef;
        position: fixed;
        z-index: 999;
        top: 50%;
        right: 6%;
    }

    .fullscreen-toggle {
        position: absolute;
        display: block;
        color: white;
        right: 0;
        bottom: 80px;
        width: 30px;
    }

    .drawer-control {
        display: none;
    }

    .drawer-container {
        max-height: 72vh !important;
        margin-top: 1vh;
    }

    .video-react {
        margin-right: -15px;
        /*height: 93vh !important;*/
        /*height: 100% !important;*/
        position: absolute;
        /*margin-left: -15px;*/
    }

    .opacity-bar {
        height: 10vh;
        background: #00a7e5;
    }

    .slidecontainer {
        width: 100%;
        background: black;
        margin-left: 15px;
        margin-right: 15px;
        display: block;
    }

    .slider:hover {
        /*opacity: 1;*/
    }

    .slider-scrub {
        position: absolute;
        top: -5px;
        width: 100%;
    }

    .scrubber {
        -webkit-appearance: none;
        width: 100%;
        height: .25rem;
        background: black;
    }

    .scrubber::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        height: 25px;
        width: 44px;
        /*border-radius: 50%;*/
        background: #bbbbbb;
        cursor: pointer;
    }

    .scrubber::-moz-range-thumb {
        width: 25px;
        height: 25px;
        background: #4CAF50;
        cursor: pointer;
    }

    .mobile-playbar {
        height: 10vh;
        width: 100%;
        margin-left: -15px;
        background: rgba(255, 255, 255, 0.7);
        position: fixed;
        /*display: flex;*/
        /*justify-content: center;*/
        bottom: 0;
        z-index: 999;
        padding-top: 14px;
    }

    .playbutton {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .playbutton i {
        font-size: 2rem;
        color: #036ea9;
    }

    .react-clock {
        width: 120px !important;
        height: 120px !important;
    }
    
    .mobile-subbar-image img {
        width: 100%;
        height: 1.5rem;
        top: 0;
        position: absolute;
        margin-right: -15px;
    }

    .center-line {
        background: black;
        height: .1rem;
        width: 100%;
        position: absolute;
        top: 7px;
    }

    .progress-bar-mobile {
        border-right: solid white 4px;
        height: 20px;
        position: absolute;
        top: 0;
        z-index: 999;
    }


    .hidden {
        display: none;
    }

    .play-bar-data-wrapper {
        display: flex;
        justify-content: space-between;
        font-size: .8rem;
        padding: 10px;
    }

    /*.mobile-big-play-button {*/
    /*    left: 50% !important;*/
    /*    top: 50% !important;*/
    /*    transform: translate(-50%, -50%);*/
    /*    -ms-transform: translate(-50%, -50%); !* for IE 9 *!*/
    /*    -webkit-transform: translate(-50%, -50%); !* for Safari *!*/
    /*}*/
    /*.mobile-drawer {*/
    /*    display: none;*/
    /*}*/
    /*. .mobile-drawer .active{*/
    /*    display: block !important;*/
    /*}*/
}

.error-message {
    padding: 2rem 0 0;
    color: #00BAF2;
}
