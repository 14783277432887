.phone-container {
    background: #719c33;
    border-radius: 25px;
    padding: 15px;
    height: 100%;
    width: 95%;
}

.phone-visual-top-wrapper {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 1rem;
}

.xyz-image-wrapper {

}

.xyz-background-image {
    /*position: absolute;*/
}

.xyz-background-image img {
    width: 100%;
}

.position-wrapper-xyz {
    position: relative;
    z-index: 99;
}

.abbrev {
    min-height: 1.2rem;
    white-space: nowrap;
    overflow: hidden;
    max-width: 9vw;
    text-overflow: ellipsis;
}

.reading {
    min-height: 1.2rem;
}

.reading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.phone-position-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 50%;
}

.phones-graph-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    color: white;
    font-size: .9rem;
    padding-bottom: 1.5rem;
}

.phone-position-animation {
    color: white;
}

.phone-image-wrapper img {
    left: 50%;
}

.phone-image-wrapper {
    position: absolute;
    width: 50%;
    height: 50%;
}

.phone-component-icon {
    display: flex;
    justify-content: flex-end;
}

.phone-component-icon img {
    width: 34px;
    height: 40px;
}

.phone-main-data-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-family: 'Comfortaa', cursive;
    font-size: .8rem;
}

.phone-main-data-wrapper > div {
    flex: 1 1 30%;
    padding-bottom: .5rem;
    text-align: center;
}

.phone-box-test {
    /*height: 110px;*/
    position: absolute;
    z-index: 999;
}

.phone-box-test img {
    width: 50%;
    margin-left: 24%;
}

/*.phone-box-test {*/
/*    border-left: solid white 4px;*/
/*    border-right: solid white 4px;*/
/*    border-bottom: solid white 10px;*/
/*    border-top: solid white 10px;*/
/*    position: absolute;*/
/*    border-radius: 8px;*/
/*    height: 90px;*/
/*    width: 40px;*/
/*    transform: rotate3d(0.2, 10, 0.4, -2.2deg);*/
/*}*/

/*.phone-box-test:before {*/
/*    content: '';*/
/*    top: 71px;*/
/*    right: 13px;*/
/*    z-index: 999;*/
/*    position: absolute;*/
/*    background: #719c33;*/
/*    width: 7px;*/
/*    height: 7px;*/
/*    border-radius: 50%;*/
/*}*/

.phone-box-test-black {
    border-left: solid black 4px;
    border-right: solid black 4px;
    border-bottom: solid black 10px;
    border-top: solid black 10px;
    position: absolute;
    border-radius: 8px;
    height: 90px;
    width: 40px;
    transform: rotate3d(0.2, 10, 0.4, -2.2deg);
}

.phone-box-test-black:before {
    content: '';
    top: 71px;
    right: 13px;
    z-index: 999;
    position: absolute;
    background: white;
    width: 7px;
    height: 7px;
    border-radius: 50%;
}

.progress-status {
    position: relative;
    height: 100%;
    margin: 4px;
    float: left;
    text-align: center;
}

.barOverflow { /* Wraps the rotating .bar */
    position: relative;
    overflow: hidden; /* Comment this line to understand the trick */
    width: 90px;
    height: 45px; /* Half circle (overflow) */
    margin-bottom: -14px; /* bring the numbers up */
}

.bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 90px;
    height: 90px; /* full circle! */
    border-radius: 50%;
    box-sizing: border-box;
    border: 15px solid #eee; /* half gray, */
    border-bottom-color: #0bf; /* half azure */
    border-right-color: #0bf;
}

.arc-data-display {
    left: 33%;
    bottom: 0;
    position: absolute;
    font-size: 12px;
}

@supports (-ms-ime-align:auto) {
    /* Edge only */
    .phone-visual-top-wrapper {
        justify-content: space-around;
    }

    .phones-graph-wrapper {
        justify-content: space-around;
    }
}
