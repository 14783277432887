.mobile-navbar-wrapper {
    background: white;
    position: relative;
    top: 0;
    width: 100%;
    height: 48px;
    display: flex;
    /*height: 100%;*/
    padding: 12px;
    z-index: 9999;
    justify-content: space-between;
    align-items: center;
}

.mobile-navbar-logo-wrapper {
    width: 170px;
    display: flex;
    padding-left: 15px;
    align-items: center;
}

.mobile-navbar-logo-wrapper img {
    width: 100%;
}

.verified-mobile-wrapper {
    width: 15%;
}

.verified-mobile-wrapper img {
    width: 100%;
}

.hamburger-inner {
    background: white !important;
}

.hamburger-inner:after {
    background: white !important;
}

.hamburger-inner:before {
    background: white !important;
}

.form-control {
    border-radius: 0 !important;
}

.form-check-inline {
    min-width: 100px;
}

.form-check-inline input {

}

.social-modal-wrapper {
    position: absolute;
    /* width: 100%; */
    /* height: 100%; */
    /*top: 100px;*/
    display: flex;
    justify-content: center;
    z-index: 999999;
    flex-direction: column;
}

.mobile-social-stable {
    width: 100%;
    margin-left: .5rem;
}

/*input[type='radio']:after {*/
/*    width: 15px;*/
/*    height: 15px;*/
/*    border-radius: 15px;*/
/*    top: -2px;*/
/*    left: -1px;*/
/*    position: relative;*/
/*    background-color: #036ea9;*/
/*    content: '';*/
/*    display: inline-block;*/
/*    visibility: visible;*/
/*    border: 2px solid white;*/
/*}*/

.navbar-radio-wrapper {
    display: flex;
    /*flex-direction: column;*/
    justify-content: flex-start;
    flex-wrap: wrap;
}

.filterOptions {
    background-color: #036ea9;
    z-index: 999;
    color: white;
    padding: 10px;
}

.navbar-drop-row {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border: solid white 0.2px;
    border-top: none;
}

.mobile-socialmedia-share img {
    width: 100%;
    height: auto;
    margin-left: .5rem;
}

.mobile-socialmedia-share {
    width: 2.5rem;
}

.mobile-navbar-menu-wrapper {
    background: linear-gradient(90deg, rgba(0, 97, 156, 1) 0%, rgba(16, 140, 177, 1) 100%, rgba(0, 0, 0, 1) 100%);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 93vh;
    z-index: 9999;
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
}

.mobile-navbar-menu-wrapper.is-active {
    transform: translate(0%, 0);
}

.mobile-navbar-login-wrapper {
    width: 75%;
}

.mobile-navbar-input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mobile-navbar-input-wrapper .mobile-navbar-search input, .mobile-navbar-input-wrapper input {
    width: 100%;
    border: solid white 1px;
    color: white;
    padding: 10px;
    background: transparent;
}

.mobile-navbar-login-button {
    float: right;
    background: transparent;
    color: white;
    font-weight: 600;
    padding: 10px;
    border: solid white 1px;
    margin-top: 10px;
    margin-bottom: 2rem;
}

.mobile-navbar-search-filter {
    margin-bottom: 20px;
}

.mobile-navbar-search input::placeholder, .mobile-navbar-filter div {
    font-family: 'Comfortaa', cursive;
}

.mobile-navbar-filter {
    width: 100%;
    background: transparent;
    border: solid white 1px;
    padding: 10px;
    font-weight: 600;
    color: white;
    display: flex;
    justify-content: space-between;
}

.mobile-navbar-search {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.mobile-navbar-radio-wrapper {
    display: flex;
}

/*.-mobile-form-check-inline .mobile-form-check-inline{*/
/*    margin-left: 40px;*/
/*}*/

.mobile-form-check-inline .mobile-form-check-input {
    /*position: static;*/
    /*margin-top: 0;*/
    /*margin-right: 0;*/
    margin-left: 40px;
}

.mobile-navbar-search i {
    position: relative;
    z-index: 1;
    left: -25px;
    top: 11px;
    cursor: pointer;
    font-weight: 600;
    color: white;
    width: 0;
}

.mobile-navbar-filter i {
}

input[type="text"]::placeholder { /* Firefox, Chrome, Opera */
    color: white;
    font-weight: 600;
}

input[type="password"]::placeholder { /* Firefox, Chrome, Opera */
    color: white;
    font-weight: 600;
}

.mobile-filter-menu-wrapper {
    width: 100%;
    position: relative;
}

.mobile-radio {
    height: 44px;
    width: 44px;
}

.form-check-label {
    width: 100%;
}

.mymedia-link {
    font-family: 'Comfortaa', cursive;
    padding-top: 1.25rem;
    font-size: 18px;
}

.login-icon-button {
    /*width: 15%;*/
    background: transparent;
    padding: 0;
    border: none;
    position: relative;
    height: 20px;
    background: none;
}

.login-icon-button img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.button-mobile-wrapper {
    display: flex;
}

.mobile-mainSearchFormInput {
    background-color: transparent;
    color: white;
    font-family: 'Comfortaa', cursive;
    height: 100%;
    border: .75px solid white;
}

.mobile-mainSearchFormInput::-webkit-input-placeholder {
    color: white;
}

.mobile-mainSearchFormInput:-moz-placeholder {
    color: white;
}

.mobile-navbar-search-wrapper input {
    /* background-image: url("/icon-search.png"); */
    background-origin: content-box;
    background-repeat: no-repeat;
    background-position: right;
    padding: .1rem .1rem .1rem .1rem;
    background-size: 12px 12px;
    font-size: .8rem;
    line-height: 1.5;
    background-clip: padding-box;
    /*width: 100%;*/
}

.mobile-navbar-filter-wrapper {
    background: linear-gradient(90deg, rgba(0, 97, 156, 1) 0%, rgba(16, 140, 177, 1) 100%, rgba(0, 0, 0, 1) 100%);
    color: white;
    font-family: 'Comfortaa', cursive;
    font-size: .8rem;
    display: flex;
    padding-top: .25rem;
    padding-bottom: .25rem;
    align-items: center;
    justify-content: space-around;
}

.width-set-notoggle {
    min-width: .6rem;
}

.mobile-nav-filter {
    display: flex;
    justify-content: space-evenly;
}

.mobile-nav-filter div {
    padding-right: .1rem;
}

.mobile-navbar-main-logowrapper {
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: 10px;
}

.mobile-nav-bar-logo-text, .mobile-user-name,
.mobile-navbar-underline-wrapper, .mobile-slash-placeholder {
    font-size: 1rem;
    color: #00639c;
}


.search-update-icon {

}

.mobile-nav-bar-underline-wrapper {
    font-size: 1rem;
    color: white;
}

.mobile-social-media-icon-wrapper img {
    height: auto;
    width: 100%;
}

.mobile-social-media-icon-wrapper {
    width: 2.5rem;
}

.mobile-logo {
    width: 32px;
    height: 32px;
    margin-right: .5rem;
}

.mobile-social-grid > div {
    padding-bottom: .5rem;
}

.mobile-social-grid {
}

@media only screen and (max-width: 834px) {
    .form-check-input {
        height: 44px;
        width: 44px;
    }
}

@media only screen and (max-width: 414px) {
    .social-modal-wrapper {
        top: 10vh;
    }
}

/*Tablets */
@media only screen and (max-width: 768px) and (min-width: 414px) {
    .login-icon-button {

    }
}

.hide-opacity {
    opacity: 0;
    height: 0;
    padding: 0;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
}

.media-set {
    -webkit-transition: opacity 2s ease-in-out;
    -moz-transition: opacity 2s ease-in-out;
    -ms-transition: opacity 2s ease-in-out;
    -o-transition: opacity 2s ease-in-out;
    opacity: 1;
}
