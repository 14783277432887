.dataButton {
    display: inline-block
}

.dataHeader {
    display: inline-block;
    margin-right: 10%;
}

.metaListItem {
    overflow: auto;
}

.drawer-container {
    /*max-height: 83vh;*/
    overflow: scroll;
    max-width: 100%;
    overflow-x: hidden
}

html {
    overflow: scroll;
    overflow-x: hidden;
}

.drawer-container::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

.desktop-fullscreen-toggle {
    color: white;
    position: absolute;
    z-index: 999;
    right: 15px;
    top: 380px;
    width: 30px;
}

.desktop-fullscreen-toggle img {
 width: 100%;
}

.desktop-fullscreen-toggle-open {
    color: white;
    position: absolute;
    z-index: 999;
    right: 15px;
    bottom: 0;
    width: 30px;
}

.desktop-fullscreen-toggle-open  img {
    width: 100%;
}

.drawer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.5rem;
}

.drawer-wrapper > * {
    margin-bottom: 1rem
}

.fullscreen-desktop-drawer {
    position: absolute;
    z-index: 99999;
}

.summary-container, .location-container, .encryption-container, .phone-container, .time-container, .connection-container, .networks-container {
    border: solid 6px white;
}

@media only screen and (max-width: 834px) {
    .drawer-wrapper {
        padding-top: 1rem;
    }
}

@media only screen and (min-width: 834px) {
    .fullscreen-desktop-media .video-react {
        position: fixed !important;
        right: 0 !important;
        bottom: 0 !important;
        min-width: 100% !important;
        min-height: 100% !important;
        background: black !important;
    }
}