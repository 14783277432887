/*.edit-wrapper {*/
/*    margin: 0 20% 0 20%*/
/*}*/

.thumbnail-cover-image {
    display: block;
    width: 100%;
    height: auto;
    overflow: hidden;
    top: 0;
}

.return-title {
    font-size: 12px;
    font-family: 'Comfortaa', cursive;
    font-weight: 600;
    margin-left: 8px;
}

.return-arrow-icon {
    display: flex;
    align-items: baseline;
}

.edit-errors-display {
    font-size: .75rem;
    min-height: .75rem;
    color: #dc3545;
    text-align: left;
    padding-bottom: 1rem;
}

.rep-score-edit {
    padding-left: 15px;
    display: flex;
    justify-content: center;
    white-space: pre;
}

.rep-score-edit .reputation-modal-button {
    right: 15px;
}

.edit-media-component-cover img {
    /*display: block;*/
    /*width: 100%;*/
    /*height: auto;*/
    /*overflow: hidden;*/
    /*top: -40%;*/
    /*position: absolute;*/
    display: block;
    height: 100%;
    /*height: auto;*/
    overflow: hidden;
    position: absolute;
}

.edit-media-component-cover {
    height: 30vh;
    position: absolute;
    top: 0;
    width: 100%;
    overflow: hidden;
    background: black;
    justify-content: center;
    display: flex;
}

.edit-media-component-subbarurl-wrapper {
    width: 100%;
    padding-top: 3%;
    position: absolute;
}

.edit-media-component-subbarurl-wrapper img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.img-wrapper {
    width: 28vw;
    height: 100px;
    overflow: hidden;
}

.edit-media-relative-wrapper {
    position: relative;
    margin-left: -15px;
    margin-right: -15px;
}

.edit-media-component-media-wrapper {
    height: 0;
    padding-top: 30vh;
    margin-bottom: 1.5rem;
    background: white;
    /*position: relative;*/
}

.media-component-media-wrapper-list-edit img {
    width: 100%;
}

.edit-thumbnail-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.thumbnails div {
    font-size: 1rem;
    font-family: 'Comfortaa', cursive;
    font-weight: 600;
}

.media-column {
    padding-top: 10px
}

.edit-media-component-timeblock {
    bottom: 20%;
    margin-right: 2%;
    margin-bottom: 5%;
    padding: 2px;
    position: absolute;
    right: 0;
    font-size: 14px;
    color: white;
    z-index: 99;
}

.edit-media-component-timeclock {
    background: transparent;
    color: white;
    font-size: .9rem;
    font-weight: 600;
}

@media only screen and (min-width: 834px) {
    .mobile-navbar-wrapper {
        display: none !important;
    }

    .mobile-footer-content-wrapper {
        display: none !important;
    }

    .img-wrapper {
        width: 80%;
    }

    .media-input-edit {
        width: 90%;
    }

    .edit-media-component-timeblock {
        bottom: 30%;
    }

    .edit-media-component-timeclock {
        background: transparent;
    }

    /*.edit-media-relative-wrapper {*/
    /*    padding-bottom: 2rem;*/
    /*}*/
    .edit-media-relative-wrapper {
        margin-right: 0;
        margin-left: 0;

    }
}

@media only screen and (max-width: 414px) {
    .media-input-edit input, textarea {
        padding: 5px;
        font-size: 16px !important;
    }
}

/*-------------New CSS--------------------*/

/*--------------DeskTopEdit--------------------*/
.save-changes {
    display: flex;
    align-items: center;
}

.save-changes button {
    font-size: 12px;
}

.save-changes i {
    padding-left: 10px;
    font-size: 18px;
}

.save-changes .btn2 {
    background-color: #D3D3D3;
}

.edit-nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #D3D3D3;
    padding-bottom: 5px;
}

.edit-nav-header .btn2:focus {
    outline: none;
    box-shadow: none;
}

.edit-nav-header .btn1:focus {
    outline: none;
    box-shadow: none;
}

.edit-cont-center {
    padding: 3rem 1.7rem;
}

.edit-cont-center .btn {
    color: darkgrey;
    font-weight: bold;
}

.editing-button1 {
    color: #0275d8 !important;
}

.editing-button2 {
    background-color: #0275d8 !important;
    color: white !important;
}

.main-content-wrapper {
    display: flex;
    padding-top: 1rem;
}

/*--------------DeskTopEdit--------------------*/

/*--------------Media Stats Start--------------*/

.media-statistics-wrapper {
    padding-top: 10px;
}

.media-stats-icon-wrapper {
    height: 20px;
    width: 20px;
}

.media-statistics-wrapper ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.media-statistics-wrapper li {
    align-items: flex-end;
}

.media-statistics-wrapper ul li {
    display: flex;
    padding: 6px;
    font-family: 'Comfortaa', cursive;
    font-size: 12px;
}

.media-statistics-wrapper ul li div {
    margin-right: 15px;
}

.media-stats-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/*-------------Media Stats End---------------*/


/*-------------Visibility Component Start----------*/
.visibility-component-wrapper {
    padding: 10px 5px;

}

.visibility-component-wrapper p {
    padding-left: 45px;
}

.visibility-content {
    border: 1px solid #D3D3D3;
    padding: 5px;
    display: flex;
    height: 100%;
    flex-direction: column;
}

.visibility-content i {
    padding-bottom: 3px;
    padding-left: 5px;
}

.visibility-content label {
    padding-left: 5px;
}

.visibility-content span {
    padding-bottom: 5px;
    font-weight: bold;
    font-family: 'Comfortaa', cursive;
    font-size: 14px;
}

.visibility-form-check-inline {
    display: flex;
    align-items: center;
}

.visibility-form-wrapper p {
    font-size: 12px;
    font-family: 'Comfortaa', cursive;
}

.radio-button-wrapper {
    font-size: x-large;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

input[type='radio'] {
    margin-right: 4px;
    margin-bottom: 4px;
}

.vis-icon-image {
    width: 100%;
    height: 100%;
    object-fit: contain
}

.vis-icon-wrapper {
    height: 24px;
    width: 24px;
    margin-bottom: 8px;
}

/*-------------Visibility Component End----------*/


/*-------------Delete Component Start----------*/
.delete-component-wrapper {
    display: flex;
    padding: 5px;
    border: 1px solid #D3D3D3;
}

.delete-component-container {
    padding: 10px 5px;
}

.delete-check-box {
    margin-right: 12px;
}

.left-side-wrapper {
    font-family: 'Comfortaa', cursive;
}

.delete-label {
    font-size: 1rem;
    font-weight: bold;
    color: #ff4040;
}

.delete-description {
    font-size: 12px;
}

.delete-component-content {
    border: 2px solid #D3D3D3;
    padding: 5px;
    display: flex;
    height: 100%;
    /*flex-direction: column;*/
}

.delete-component-content label {
    color: red;
}

/*-------------Delete Component Start----------*/


/*-------------Thumbnail Component Start----------*/
.thumbnails {
    border: 1px solid #D3D3D3;
    padding: 5px;
}

.img-wrapper {
    opacity: 0.4;
}

/*-------------Thumbnail Component End----------*/

/*---------------Mobile CSS Start---------------*/


@media only screen and (max-width: 414px) {
    .edit-cont-center {
        padding: 3.4rem 0 0 0;
        font-size: 12px !important;
        flex-wrap: inherit;
        margin: 0 0 0 0;
    }

    .edit-cont-center .row {
        border-top: 2px solid #D3D3D3;
        padding-top: 5px;
    }

    .btn {
        font-size: inherit;
    }

    .save-changes {
        padding-right: 5px;
    }

    .main-content-wrapper {
        display: inline;
    }

    /*
 This breaks mobile Grid view, find better solution
    .container-fluid{
        padding:0;
    }
    */
    .custom-input-wrapper {
        margin-right: 0;
    }

    .visibility-component-wrapper {
        padding: 0;
        margin-bottom: 10px;
    }

    .media-information-wrapper {
        padding-left: 5px;
        padding-right: 5px;
    }

    .delete-component-wrapper {
        padding: 5px;
        margin-bottom: 10px
    }

    .thumbnail-component-wrapper {
        margin-bottom: 10px
    }

    .media-statistics-wrapper {
        margin-bottom: 10px;
        padding-top: 0;
    }

    .edit-nav-header {
        padding-left: 5px;
    }
}


/*---------------Mobile CSS End---------------*/

/*-----------------New CSS End------------------*/
