.summary-container {
    background: #15588d;
    border-radius: 25px;
    padding: 15px;
    height: 100%;
    width: 95%;
}

.read-out-wrapper {
    /*position: absolute;*/
    top: -42px;
    /*display: flex;*/
    /*align-items: center;*/
    /*flex-direction: column;*/
    float: right;
}

.playbar-duration-reason-summary{
    /*top: -58px;*/
    /*left: 141px;*/
    font-family: 'Comfortaa', cursive;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 4px;
    padding-bottom: 2px;
    white-space: pre;
    font-size: .7rem;
    border-radius: 4px;
    /*position: relative;*/
}

.summary-recording-data-title {
    font-family: 'Comfortaa', cursive;
}

.summary-recording-data-wrapper, .summary-recording-data-wrapper-btm div {
    font-family: 'Comfortaa', cursive;
    font-size: .8rem;
}

.summary-graph {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
}

.summary-graph-datas-wrapper {
    font-family: 'Comfortaa', cursive;
    font-size: .8rem;
}

.graph-color-block-green {
    width: 20px;
    height: 20px;
    background: var(--vl_green);
}

.graph-color-block-red {
    width: 20px;
    height: 20px;
    background: var(--vl_red);
}

.graph-color-block-yellow {
    width: 20px;
    height: 20px;
    background: var(--vl_yellow);
}

.summary-graph-data-wrapper {
    display: flex;
    align-items: center;
    color: white;
}

.summary-component-icon img {
    width: 34px;
    height: 34px;
}

.summary-component-icon {
    display: flex;
    justify-content: flex-end;
}

.graph-data {
    padding-left: 10px;
}

.summary-graph-wrapper {
    display: flex;
}

.summary-graph-wrapper {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
    /*flex-direction: column;*/
}

.summary-graphs-wrapper {
    /*display: flex;*/
    /*justify-content: space-around;*/
    /*color: white;*/
}

.summary-graph-data-bar {
    height: 20px;
    width: 100%;
}

.summary-container > div {

}

.summary-graph-datas-wrapper > div {
    padding-bottom: .25rem;
}

.summary-graph-progresspoint {
    border-right: solid white 4px;
    /*height: 20px;*/
    /*position: absolute;*/
    /*top: 4px;*/
}

.summary-graph-data-bar-wrapper {
    position: relative;
}

.summary-recording-data-wrapper {
    display: grid;
    grid-template-columns: 1fr 2fr;
    color: white;
}

.summary-recording-data-wrapper-btm {
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: white;
}

.summary-recording-data-title {
    color: white;
}

.summary-recording-data-container {
    padding-bottom: 1rem;
}

@media only screen and (max-width: 834px) {
    .summary-graph {
        position: relative;
        /*height: 25vh;*/
        /*width: 80vw*/
    }
}



