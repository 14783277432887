
.flex-center-content-upload > input {
    display: none;
}

.recommended-size {
    padding-bottom: 10px;
}

.profilemedia-uploader img {
    cursor: pointer;
    width: 100%;
    height: 100%;
}

.upload-modal-container-background {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 9999999999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-modal-wrapper {
    background: white;
    opacity: 1;
    font-family: 'Comfortaa', cursive;
    font-weight: bold;
    font-size: 16px;
}

.upload-button {
    color: white;
    background: #00a7e5;
    font-family: 'Comfortaa', cursive;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    padding: 3px;
    border-radius: 200px;
}

.content-wrapper-upload-modal {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    /*align-items: center;*/
}

.flex-center-content-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.top-wrapper-modal {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-right: 10px;
    padding-top: 5px;
}

.top-wrapper-modal div {
    cursor: pointer;
}