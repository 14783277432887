.editmodal-container {
    /*width: 100%;*/
    position: relative;
    display: flex;
    /*apply via props
    justify-content: flex-end;
    */
}

.editmodal-text-icon-wrapper {
    display: flex;
}

.editmodal-icon-wrapper {
    cursor:pointer;
}

.editmodal-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.edit-modal-body-container {
    position: absolute;
    background: white;
    z-index: 1000;
    top: 40px;
    right: 8px;
    box-shadow: 2px 2px 5px 6px rgba(0, 0, 0, 0.24);
}

.editmodal-list-icon-wrapper {
    height: 14px;
    width: 14px;
}

.editmodal-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.edit-modal-body-container ul {
    list-style: none;
    /*margin-bottom: 0;*/
    margin: 0;
    padding: 0;
}

.edit-modal-body-container ul li {
    display: flex;
    justify-content: space-between;
    padding: 6px;
    font-family: 'Comfortaa', cursive;
    font-size: 12px;
    cursor:pointer;
}

.edit-modal-body-container ul li div {
    margin-right: 4px;
}

.edit-icon-style {
    border-top: 1px solid #D3D3D3;
}
