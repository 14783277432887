/*@import url("https://fonts.googleapis.com/css?family=Comfortaa:400,600,700&display=swap");*/

:root {
    --vl_green: #4bd964;
    --vl_yellow: #fccf10;
    --vl_red: #ff4040;
    --vl_grey: #bfbfbf;
    --vl_lt_blue: #02bbef;

    --vl_half_drawer_spacing: 4vh;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* touch-action: pan-x pan-y; */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1, h2, h3, h4 {
    font-family: 'Comfortaa', cursive;
    font-weight: 600;
}

h4 {
    font-size: 1.4rem;
    color: black;
}

.logo-image-wrapper {
    height: 55px;
    width: 55px;
    margin-right: .5rem;
}

.logo-image-wrapper img {
    width: 100%;
    height: auto;
}

.social-media-top-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.width-set-search {
    width: 45%;
}

.social-media-icon-wrapper {
    width: 2.5rem;
    padding-left: 5px;
    padding-top: 10px;
    cursor: pointer;
}

.social-media-icon-wrapper img {
    width: 100%;
    height: auto;
}

.desk-fullscreen-toggle {
    position: absolute;
    top: 55%;
    right: 10px;
    color: white;
    font-size: 2rem;
}

.container-fluid {
    min-height: 72vh;
    height: 100%;
    position: relative;
    /*top: 8vh;*/
}

.input-style {
    width: 100%;
}

.mainTopNavBackground {
    background: rgb(0, 97, 156);
    background: linear-gradient(90deg, rgba(0, 97, 156, 1) 0%, rgba(16, 140, 177, 1) 51%);
    height: 110px;
}

.mainTopNavFilter {
    display: flex;
    align-items: flex-end;
    /*height: 70px;*/
    background: rgb(21, 138, 208);
    background: linear-gradient(90deg, rgba(21, 138, 208, 1) 0%, rgba(16, 140, 177, 1) 100%, rgba(0, 0, 0, 1) 100%);
    padding: 5px;
    margin-bottom: 2%;
}

.navTopRight {
    position: absolute;
    right: 50px;
    padding: 40px;
}

/*.mainFooterNavBackground {*/
/*    background-image: url("/footerBackgoundShade.png");*/
/*    height: 150px;*/
/*    background-color: #00a7e5;*/
/*    margin-top: 100px;*/
/*    color: white;*/
/*    margin-right: 5%;*/
/*    margin-left: 5%;*/
/*    padding: 3%;*/
/*}*/

.topLogoArea {
    height: 100px;
    width: 300px;
}

.colorWhite {
    color: white;
}

.divVideoOverlay {
    width: 75px;
    height: 35px;
    z-index: 999;
    position: absolute;
    background: rgba(0, 65.5, 89.8, 0.65);
    padding: 5px;
    color: white;
}

.divVideoPlayOverlay {
    width: 300px;
    height: 220px;
    z-index: 9999;
    position: absolute;
    /*background: rgba(255, 65.5, 89.8, 0.5);*/
    /*background-image: url("/playVideo.png");*/
    color: white;
}

.divVideoPlayOverlay:hover {
    width: 300px;
    height: 220px;
    z-index: 9999;
    position: absolute;
    /*background: rgba(255, 65.5, 89.8, 1);*/
    background-image: url("/playVideo.png");
    color: white;
}

.mainFormSearchFilter {
    width: 75%;
}

.mainFilterFormInput {
    display: flex;
    background: transparent;
    justify-content: space-between;
    color: white;
    align-items: center;
    font-family: 'Comfortaa', cursive;
}

.mainSearchFormInput::placeholder {
    /* Firefox, Chrome, Opera */
    color: white;
    opacity: 1;
}

.searchFilterButtons {
    background-color: #036ea9;
    border-color: white;
    color: white;
    font-size: 16px;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.searchFilterButtons:hover {
    background-color: white;
    border-color: white;
    color: #036ea9;
}

.filterOptions {
    width: 300px;
    height: 100px;
    background-color: #036ea9;
    z-index: 999;
    border-color: white;
    color: white;
    border-block: 1px;
    padding: 3%;
}

.videoContainer {
    margin-top: 50px;
    padding-top: 50px;
}

.navLogoutButton,
.navLoginButton {
    font-weight: 600;
    font-size: 1rem;
    padding: 10px;
    /*border: 2px solid #02bbef;*/
    border-radius: 0;
    font-family: 'Comfortaa', cursive;
    color: #00BAF2;
}

.videoListImage {
    float: left;
    margin-bottom: 5%;
    margin-right: 2%;
}

.videoListDisplay {
    margin-bottom: 5%;
}

.videoListDetails {
    /*margin-left: 3%;*/
}

hr {
    height: 1px;
    margin: 0;
}

.adminMenuItem {
    background-color: #e5e5e5;
    margin: 2%;
    padding: 2%;
    padding-left: 4%;
    width: 350px;
}

.adminEditShare {
}

.adminEditShareItem {
    font-weight: 600;
    font-size: 20px;
    padding: 10px;
    border: 2px solid #02bbef;
    color: #02bbef;
}

.toolTipWrapper {
    text-align: center;
}
.toolTipButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.topRightNavLinksContainer {
    margin-top: 5%;
}

.topRightNavLoginButtonContainer {
    margin-top: 5%;
}

.navbar-main-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.navbar-main-wrapper {
    background: white;
    /*background: linear-gradient(90deg, rgba(0, 97, 156, 1) 0%, rgba(16, 140, 177, 1) 100%, rgba(0, 0, 0, 1) 100%);*/
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 16px;
    padding-left: 16px;
}

.navbar-main-username {
    color: #00639c;
    font-size: 1rem;
    text-align: right;
    /*padding-right: 12%;*/
}

.navbar-main-options-wrapper {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    padding-right: 20px;
}

.navbar-main-options-wrapper ul {
    margin-top: 0;
    margin-bottom: 0;
}

.navbar-main-options-wrapper ul li {
    display: inline-block;
    color: white;
    font-size: 1rem;
    font-family: 'Comfortaa', cursive;
    /*font-weight: 600;*/
    text-decoration: none;
}

.navbar-main-content-right {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.navbar-main-options-wrapper ul li a {
    color: #00639c;
    text-decoration: none;
}

.navbar-main-options-wrapper ul li a:hover {
    border-bottom: solid 1.5px white;
}

.navbar-top-fixed {
    position: fixed;
    top: 0;
    z-index: 999999999;
    width: 100%;
}

.nav-bar-logo-text, .nav-bar-logo-text a {
    color: #00639c;
    font-size: 1.5rem;
    font-family: 'Comfortaa', cursive;
    padding-top: 12px;
    font-weight: 600;
    letter-spacing: -1px;
}

.nav-bar-logo-text a:hover {
    color: #00639c;
}

.underline-bar {
    height: .15rem;
    width: 100%;
    background-color: #00639c;
    border-radius: 4px;
    /*margin-left: -5px;*/
}

.slash-placeholder {
    color: #00639c;
    font-size: 1.5rem;
    font-family: 'Comfortaa', cursive;
    padding-top: 12px;
}

.user-name {
    color: #00639c;
    font-size: 1.5rem;
    font-family: 'Comfortaa', cursive;
    padding-top: 12px;
}

.navbar-main-logowrapper {
    display: flex;
    /*align-items: center;*/
}

.navbar-main-logowrapper img {
    margin-right: 10px;
}

@media only screen and (max-width: 834px) {
    .mainTopNavBackground {
        display: none;
    }

    .mainTopNavFilter {
        display: none;
    }

    .navbar-main-wrapper {
        display: none;
    }

    .footer-content-wrapper {
        display: none;
    }

    .container-fluid {
        top: 0;
        position: inherit;
    }
}

/*Prevents user from flipping to landscape mode*/
@media screen and (max-width: 834px)  and (orientation: landscape) {
    /*html {*/
    /*    transform: rotate(90deg);*/
    /*    transform-origin: left top;*/
    /*    width: 100vh;*/
    /*    overflow-x: hidden;*/
    /*    position: absolute;*/
    /*    top: 100%;*/
    /*    left: 0;*/
    /*}*/
}

@media only screen and (min-width: 834px) {
    .mobile-navbar-wrapper {
        display: none !important;
    }

    .mobile-footer-content-wrapper {
        display: none !important;
    }
}
